import classNames from "classnames";
import { Button, TitleText } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { CgClose, CgSpinner } from "react-icons/cg";
import { FiAlertCircle } from "react-icons/fi";

const FormModal = ({
  title = "",
  open,
  onClose,
  onSubmit,
  children,
  className = "",
  bodyClassname = "",
  isLoading = false,
  renderAction = () => <></>,
  _submitButton,
  isHideSubmit = false,
}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-20">
          {/* <motion.div
            key="modal-confirmation"
            className="bg-white rounded-xl w-1/2"
            initial={{ scale: 0 }}
            animate={{ scale: 1, animation: 10 }}
            exit={{ scale: 0 }}
          > */}
          <div
            key="modal-confirmation"
            className={className ? className : "bg-white rounded-xl w-1/2"}
          >
            <div className="flex flex-col py-4 space-y-4">
              <div className="flex-1 flex items-center justify-between px-8">
                <TitleText className="">{title}</TitleText>
                <CgClose
                  className="text-xl cursor-pointer"
                  onClick={() => onClose(false)}
                />
              </div>
              <div
                className={classNames([
                  "flex-1 border-y max-h-[320px] xl:max-h-[512px] overflow-y-auto px-8 py-4",
                  ...bodyClassname?.split(" "),
                ])}
              >
                {children}
              </div>
              <div className="flex-1 flex items-center space-x-4 px-8">
                <Button
                  type="button"
                  className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 flex-1"
                  disabled={isLoading}
                  onClick={() => onClose(false)}
                >
                  Batal
                </Button>
                {renderAction()}
                {
                  isHideSubmit ? null : (
                <Button
                  type="button"
                  className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1 space-x-1"
                  disabled={isLoading || _submitButton?.disabled}
                  onClick={onSubmit}
                >
                  <span>{_submitButton?.label ?? "Submit"}</span>
                  {isLoading && <CgSpinner className="animate-spin" />}
                </Button>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default FormModal;
