import { useParams } from "react-router";

import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { Breadcrumbs, Card, CardBody, CardHeader, Table } from "components";
import FilePickerForm from "components/HookForm/FilePickerForm";
import { useActivityDetailQuery } from "hooks/downPayment";
import { map } from "lodash";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import StatusBadge from "./StatusBadge";
import { convertToIDR } from "helpers";

const DetailItem = ({ title, value, className }) => (
  <div className={classNames("flex flex-col gap-2", className)}>
    <span className="text-sm font-semibold">{title}</span>
    <span>{value}</span>
  </div>
);

const DownPaymentActivityDetail = () => {
  const { id } = useParams();

  const { data } = useActivityDetailQuery(["dp-detail", id]);

  const links = [
    {
      label: "Uang Muka",
      path: "/down-payment-activity",
    },
    {
      label: "Daftar UM Kegiatan",
      path: "/down-payment-activity",
    },
    {
      label: "Detail Kegiatan",
    },
  ];

  const schema = yup.object();

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const tableColumns = [
    {
      id: "nip",
      title: "NIP",
      dataIndex: "nip",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        );
      },
    },
    {
      id: "employee_name",
      title: "Nama Pegawai",
      dataIndex: "name",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        );
      },
    },
    {
      id: "gender",
      title: "Jenis Kelamin",
      dataIndex: "gender",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        );
      },
    },
    {
      id: "role",
      title: "Jabatan",
      dataIndex: "position",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        );
      },
    },
    {
      id: "division",
      title: "Divisi",
      dataIndex: "division",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        );
      },
    },
  ];


  const tableColumnsRealization = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "activity_detail",
      title: "Detail Kegiatan",
      dataIndex: "detail_code",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className="flex flex-col overflow-hidden text-ellipsis w-full max-w-[540px]">
            <span className="font-medium">{data?.name}</span>
            <span>{data?.account_code}</span>
          </div>
        );
      },
    },
    {
      id: "subtotal",
      title: "Sub Total",
      dataIndex: "subtotal",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {convertToIDR(value)}
          </div>
        );
      },
    },
  ];

  const isDomestic = data?.destination_type === "domestic";

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="space-y-4">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="space-y-4">
                <div className="text-lg font-semibold flex flex-row items-center gap-2">
                  <span>Detail Kegiatan Utama</span>
                  <StatusBadge status={data?.status?.description} />
                </div>
                <div className="grid grid-cols-3 gap-6">
                  <DetailItem
                    title="Tahun Anggaran"
                    value={data?.fiscal_year}
                  />
                  <DetailItem
                    title="Jenis Kegiatan"
                    value={map(data?.activity_types, "name").join(", ")}
                  />
                  <DetailItem
                    title="Nama Kegiatan"
                    value={data?.activity_name}
                    className="col-start-1"
                  />
                  <DetailItem
                    title="Destinasi"
                    value={isDomestic ? "Dalam Negeri" : "Luar Negeri"}
                    className="col-start-1"
                  />
                  {isDomestic ? (
                    <>
                      <DetailItem
                        title="Kota Asal/Keberangkatan"
                        value={data?.city}
                      />
                      <DetailItem
                        title="Kota Tujuan"
                        value={data?.destination}
                      />
                    </>
                  ) : (
                    <>
                      <DetailItem
                        title="Negara Asal/Keberangkatan"
                        value={data?.country}
                      />
                      <DetailItem
                        title="Negara Tujuan"
                        value={data?.destination}
                      />
                    </>
                  )}

                  <DetailItem
                    title="Tanggal Mulai Kegiatan"
                    value={moment(data?.start_date).format("DD/MM/YYYY")}
                  />
                  <DetailItem
                    title="Tanggal Selesai Kegiatan"
                    value={moment(data?.end_date).format("DD/MM/YYYY")}
                  />
                  <DetailItem
                    title="PUM"
                    value={`${data?.pum?.nip} - ${data?.pum?.name}`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="space-y-4">
                <div className="text-lg font-semibold">Komponen Kegiatan</div>
                <div className="grid grid-cols-3 gap-4">
                  <DetailItem
                    title="Komponen Kegiatan"
                    value={data?.component_code}
                    className="col-start-1"
                  />
                </div>
              </div>
            </div>
          </div>

          <Card>
            <CardHeader>
              {" "}
              <span className="text-sm inline-block font-semibold bg-blue-50 text-blue-700 rounded-xl px-3 py-1">
                Total Nilai:{" "}
                <span className="font-bold">{convertToIDR(data?.accounts?.total)}</span>
              </span>
            </CardHeader>
            {
              data?.accounts?.details.length > 0 && (
                <CardBody className="space-y-3">
                  {data?.accounts?.details.map((account, key) => (
                    <div
                      className="rounded-lg border-[1px] border-gray-100"
                      key={key}
                    >
                      <div className="flex flex-row gap-4 items-center bg-[#E1F1D6] px-4 py-2 rounded-t-lg">
                        <span className="text-primary-600 font-bold">
                          Akun Belanja:{" "}
                          <span className="font-medium">{account?.account_code}</span>
                        </span>
                        <span className="text-sm inline-block font-semibold bg-warning-50 text-warning-700 rounded-xl px-3 py-1 mix-blend-multiply">
                          Total Nilai Realisasi:{" "}
                          <span className="font-bold">
                            {convertToIDR(account?.subtotal)}
                          </span>
                        </span>
                      </div>
                      <div className="p-4">
                        <Table
                          bordered
                          stripped
                          layout="fixed"
                          className="mb-4 rounded-xl"
                          columns={tableColumnsRealization}
                          dataSource={account?.details}
                          pagination={{
                            page: 1,
                            limit: 20,
                            total: account?.detail?.length,
                            previous_pages: false,
                            next_pages: false,
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </CardBody>
              )
            }
          </Card>

          
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="space-y-4">
                <div className="text-lg font-semibold">Surat Tugas</div>
                <div className="grid grid-cols-2 gap-4">
                  <DetailItem
                    title="No Surat Tugas"
                    value={data?.assignment_letter_number}
                  />
                  <DetailItem
                    title="Tanggal Surat Tugas"
                    value={moment(data?.assignment_letter_date).format(
                      "DD/MM/YYYY"
                    )}
                  />
                  <FilePickerForm
                    name="job_document"
                    label="Dokumen Pendukung"
                    file={data?.assignment_document}
                    readOnly
                  />
                </div>
              </div>
              <div className="border-t-[1px] border-gray-200 py-4">
                <div className="space-y-4">
                  <span className="text-lg text-primary-600 font-semibold ">
                    Daftar Pegawai yang Dimasukkan ke dalam Surat Tugas
                  </span>
                  <Table
                    bordered
                    stripped
                    layout="fixed"
                    className="mb-4"
                    columns={tableColumns}
                    dataSource={data?.staffs}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="space-y-4">
                <div className="text-lg font-semibold">Dokumen Pendukung</div>

                <div className="grid grid-cols-2">
                  <FilePickerForm
                    name="job_document"
                    label="Dokumen Pendukung"
                    file={data?.additional_documents?.[0]}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-24" />
      </FormProvider>
    </>
  );
};

export default DownPaymentActivityDetail;
