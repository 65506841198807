import React from "react";
import { InputForm, SelectBudgetListForm } from "components/HookForm";

const Form = (props) => {
  const detail = props?.detail;
  return (
    <div className="flex flex-col gap-4">
      <InputForm
        name={`transaction_type_name`}
        className={`py-2 px-4 border w-full rounded-md`}
        label={"Jenis Transaksi"}
        placeholder={"Jenis Transaksi"}
        disabled={detail ? true : false}
      />
      <SelectBudgetListForm
        name={`debits_account_name`}
        label="Akun Debet"
        isDisabled={detail ? true : false}
      />
      <SelectBudgetListForm
        name={`credits_account_name`}
        label="Akun Kredit"
        disabled={detail ? true : false}
        isDisabled={detail ? true : false}
      />
    </div>
  );
};

export default Form;
