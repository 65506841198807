import { useCallback, useEffect, useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { HiOutlineEye } from "react-icons/hi";
import { debounce } from "lodash";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useCoaProject, useCoaProjectUpdate } from "hooks/coaProject";

import { Breadcrumbs, Button, SearchInput, Table, TitleText } from "components";
import {
  ConfirmationModal,
  FormModal,
  InformationModal,
} from "components/Modal";
import { FiCheckCircle } from "react-icons/fi";
import Detail from "./detail";
import Form from "./form";
import { enqueueSnackbar } from "notistack";
import { useLocation, useOutletContext } from "react-router";

const CoaProject = () => {
  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);

  const [selectedData, setSelectedData] = useState(null);

  const [editModal, setEditModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);

  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const links = [
    {
      label: "COA Project",
    },
  ];

  const {
    data: list,
    isLoading,
    refetch,
  } = useCoaProject([
    "coa-project-list",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
      order: "desc",
    },
  ]);

  const update = useCoaProjectUpdate();

  const schema = yup.object().shape({
    code: yup.string().required("Kode wajib diisi"),
    full_code: yup.string().required("Fullcode wajib diisi"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: "",
      full_code: "",
    },
  });

  useEffect(() => {
    if (!editModal) {
      methods.reset();
    }
  }, [editModal]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const onTriggerDetail = (data) => {
    setSelectedData(data);
    setDetailModal(true);
  };
  const onTriggerEdit = (data) => {
    setSelectedData(data);
    setEditModal(true);
  };

  const onSubmit = (payload) => {
    update.mutate(
      { id: selectedData?.id, payload },
      {
        onSuccess: (data) => {
          setEditModal(false);
          setSelectedData(null);
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          setTimeout(() => {
            refetch();
          }, 100);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(list?.currentPage) * parseInt(list?.size) -
          parseInt(list?.size) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "full_code",
      title: "Full Code",
      dataIndex: "full_code",
      width: 540,
      className: "overflow-hidden text-ellipsis whitespace-normal flex-wrap",
      sortable: true,
      render: (value) => (
        <div className="w-full break-words max-w-[200px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "code",
      title: "Kode",
      dataIndex: "code",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "name",
      title: "Nama Akun",
      dataIndex: "name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "level",
      title: "Level",
      dataIndex: "level",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center lg:w-[40%] w-full">
          {/* {roleAccess?.canView && (
            <HiOutlineEye
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onTriggerDetail(data)}
            />
          )} */}
          {roleAccess?.canUpdate && (
            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onTriggerEdit(data)}
            />
          )}
        </div>
      ),
    },
  ];

  const renderModal = () => (
    <>
      <InformationModal
        title="Detail"
        open={detailModal}
        onClose={() => setDetailModal(false)}
        className="bg-white rounded-xl w-2/3"
        childClassName="flex-1 max-h-[70vh] overflow-y-scroll px-8 py-4"
      >
        <Detail id={selectedData?.id} />
      </InformationModal>
      <FormModal
        title={selectedData?.id ? "Edit COA Project" : "Tambah COA Project"}
        className="bg-white rounded-xl w-2/3"
        open={editModal}
        onClose={() => setEditModal(false)}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <FormProvider {...methods}>
          <Form id={selectedData?.id} />
        </FormProvider>
      </FormModal>
    </>
  );

  return (
    <>
      {renderModal()}
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">{"Daftar COA Project"}</TitleText>
        <div className="flex space-x-4 justify-end flex-1"></div>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
        <div className="card-body p-3">
          <div className="space-y-4">
            <div className="space-x-5 flex">
              <SearchInput placeholder="Cari" onChange={onSearch} />
            </div>
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={list?.result}
              isLoading={isLoading}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: list?.currentPage,
                limit: list?.size,
                total: list?.totalItems,
                previous_pages: list?.currentPage > 1,
                next_pages: !(list?.currentPage === list?.totalPages),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CoaProject;
