import { yupResolver } from "@hookform/resolvers/yup";
import { BottomFormAction, Breadcrumbs } from "components";
import {
  CustomDatepickerForm,
  InputForm,
  NumberInputForm,
  RadioGroupForm,
  SelectAccountForm,
  SelectBankAccountForm,
} from "components/HookForm";
import { useCreateCashTransfer } from "hooks/cash";
import { last } from "lodash";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import * as yup from "yup";

const schema = yup.object().shape({
  // source_account: yup.object({
  //   label: yup.string().typeError("Wajib Diisi").required(),
  //   value: yup.string().typeError("Wajib Diisi").required(),
  // }),
  // target_account: yup.object({
  //   label: yup.string().typeError("Wajib Diisi").required(),
  //   value: yup.string().typeError("Wajib Diisi").required(),
  // }),
  // source_account: yup.mixed().required("Wajib Diisi"),
  // target_account: yup.mixed().required("Wajib Diisi"),
  value: yup.number().positive().typeError("Wajib Diisi").required(),
  transaction_date: yup.date().typeError("Wajib diisi").required(),
  // source_bank_account: yup.object({
  //   label: yup.string().typeError("Wajib Diisi").required(),
  //   value: yup.string().typeError("Wajib Diisi").required(),
  //   name: yup.string().typeError("Wajib Diisi").required(),
  //   number: yup.string().typeError("Wajib Diisi").required(),
  // }),
  source_bank_account: yup.mixed().required("Wajib Diisi"),
  // target_bank_account: yup.object({
  //   label: yup.string().typeError("Wajib Diisi").required(),
  //   value: yup.string().typeError("Wajib Diisi").required(),
  //   name: yup.string().typeError("Wajib Diisi").required(),
  //   number: yup.string().typeError("Wajib Diisi").required(),
  // }),
  target_bank_account: yup.mixed().required("Wajib Diisi"),
  // category: yup.string().typeError("Wajib Diisi").required(),
  // category: yup.string().required("Wajib Diisi"),
});

const BankCashFlowForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.pathname?.includes("receipt") ? "Pemasukan" : "Pengeluaran";

  const createCashTransfer = useCreateCashTransfer();
  const links = [
    {
      label: "Daftar Pemindahan Uang Keluar",
      path: "/bank-cash-flow",
    },
    {
      label: "Tambah Pemindahan Uang Keluar",
    },
  ];

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      source_bank_account: null,
      target_bank_account: null,
      // source_account: null,
      // target_account: null,
      value: "",
      category:
        type === "receipt" ? "operational-receipt" : "operational-expense",
    },
  });

  const onSubmit = (payload) => {
    const params = {
      source_account_code: payload?.source_account?.value,
      source_account_name: payload?.source_account?.name,
      target_account_code: payload?.target_account?.value,
      target_account_name: payload?.target_account?.name,
      category: payload?.category,
      transaction_date: moment(payload.transaction_date).format("YYYY-MM-DD"),
      source_bank_account_id: payload?.source_bank_account?.id,
      target_bank_account_id: payload?.target_bank_account?.id,
      is_draft: false,
      value: payload?.value,
      description: payload?.description,
    };

    createCashTransfer.mutate(
      { type: type === "Pemasukan" ? "receipt" : "expense", payload: params },
      {
        onSuccess: (data) => {
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          navigate(-1);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="space-y-4">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
            <div className="card-body p-4 space-y-4">
              <div className="text-lg font-semibold mb-3">
                Tambah Pemindahan Uang Keluar - Bendahara{" "}
                {/* {type === "receipt" ? "Penerimaan" : "Pengeluaran"} */}
                {type}
              </div>
              {/* <RadioGroupForm
                label="Kategori Kas"
                controllerName="category"
                values={[
                  {
                    label: "Operasional Pengeluaran",
                    value: "operational-expense",
                  },
                  {
                    label: "Operasional Penerimaan",
                    value: "operational-receipt",
                  },
                  {
                    label: "Dana Kelolaan",
                    value: "managed",
                  },
                ]}
              /> */}
              <CustomDatepickerForm
                name="transaction_date"
                label="Tanggal Permintaan"
                placeholder={"dd/mm/yyyy"}
              />
              <div className="grid grid-cols-2 gap-4">
                <SelectBankAccountForm
                  label="Nomor Rekening Sumber/Asal"
                  name="source_bank_account"
                  placeholder="Pilih Nomor Rekening"
                  type={type}
                  required
                />
                {/* <SelectAccountForm
                  name="source_account"
                  label="Akun Sumber/Asal"
                  placeholder="Pilih Akun"
                  level="9"
                  required
                /> */}
                <InputForm
                  name="source_account_number"
                  label="Nama Pemilik Rekening"
                  className="w-full"
                  disabled
                  value={methods.watch("source_bank_account")?.name}
                  placeholder="Pilih Nomor Rekening"
                  required
                />
                <InputForm
                  name="source_bank"
                  label="Bank"
                  className="w-full"
                  placeholder="Pilih Nomor Rekening"
                  disabled
                  value={methods.watch("source_bank_account")?.bank_name}
                  required
                />
              </div>
              <NumberInputForm
                label="Nominal Pemindahan"
                name="value"
                className="w-full"
                placeholder="Masukkan Nominal"
              />
              <div className="grid grid-cols-2 gap-4">
                <SelectBankAccountForm
                  label="Nomor Rekening Tujuan"
                  name="target_bank_account"
                  placeholder="Pilih Nomor Rekening"
                  // type={type === "receipt" ? "Pengeluaran" : "Pemasukan"}
                  type={type === "Pemasukan" ? "Pengeluaran" : "Pemasukan"}
                  required
                />
                {/* <SelectAccountForm
                  name="target_account"
                  label="Akun Tujuan"
                  placeholder="Pilih Akun"
                  level="9"
                  required
                /> */}
                <InputForm
                  name="target_account_number"
                  label="Nama Pemilik Rekening"
                  className="w-full"
                  disabled
                  value={methods.watch("target_bank_account")?.name}
                  placeholder="Pilih Nomor Rekening"
                  required
                />
                <InputForm
                  name="target_bank"
                  label="Bank"
                  className="w-full"
                  placeholder="Pilih Nomor Rekening"
                  disabled
                  value={methods.watch("target_bank_account")?.bank_name}
                  required
                />
              </div>
              <InputForm
                name="description"
                label="Deskripsi"
                className="w-full"
                type="textarea"
              />
            </div>
          </div>
        </div>
        <div className="h-32"></div>
        <BottomFormAction
          onSubmit={methods.handleSubmit(onSubmit)}
          onCancel={() => navigate(-1)}
        />
      </FormProvider>
    </>
  );
};

export default BankCashFlowForm;
