import classNames from "classnames";
import { useGetFile } from "hooks/externalService";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useFormContext } from "react-hook-form";
import { BiXCircle } from "react-icons/bi";
import { FiDownloadCloud, FiFile, FiUploadCloud } from "react-icons/fi";

const FilePickerForm = ({
  name,
  label,
  required,
  readOnly,
  file,
  onDownload,
  ...props
}) => {
  const { control } = useFormContext();

  const [idFile, setIdFile] = useState(null);

  const { data, isFetching } = useGetFile(idFile, { enabled: !!idFile });

  useEffect(() => {
    if (!isFetching) {
      setIdFile(null);
      if (data) window.open(data?.data?.url, "_blank");
    }
  }, [isFetching]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <div>
          <label className="flex gap-1 font-semibold text-[14px]">
            <span className={`label-text`}>{label}</span>
            {required && <span className="text-[#F04438]">*</span>}
          </label>
          <div
            className={classNames(
              "flex flex-col justify-center w-full border-gray-200 border-[1px] rounded-lg mt-2",
              readOnly ? "p-4 items-start" : "p-6 items-center"
            )}
          >
            {readOnly && file?.length > 0 ? (
              file?.map((f, i) => {
                return (
                  <div
                    key={i}
                    className="flex flex-row gap-4 justify-between w-full pb-4"
                  >
                    <div className="bg-primary-50 rounded-full p-1 self-start">
                      <div className="bg-primary-100 rounded-full p-1">
                        <FiFile className="text-primary-600 cursor-pointer" />
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 flex-1 items-start">
                      <span className="text-sm font-semibold">
                        {f?.file_name ?? "Document"}
                      </span>
                      <span className="text-sm">
                        {Math.round(f?.file_size / 1000)}KB
                      </span>
                      <button
                        className="text-primary-600 mt-2 cursor-pointer text-sm font-semibold"
                        type="button"
                        onClick={() => {
                          const fileId = f?.fileId ?? f?.id ?? f?.file_id;
                          if (fileId) {
                            setIdFile(fileId);
                          } else {
                            window.open(f?.url, "_blank");
                          }
                        }}
                      >
                        Buka Dokumen
                      </button>
                    </div>
                    <FiDownloadCloud
                      onClick={() => {
                        const fileId = f?.fileId ?? f?.id ?? f?.file_id;
                        if (fileId) {
                          setIdFile(fileId);
                        } else {
                          window.open(f?.url, "_blank");
                        }
                      }}
                      className="text-gray-600 cursor-pointer"
                      size={20}
                    />
                  </div>
                );
              })
            ) : readOnly ? (
              <div className="flex flex-row gap-4 justify-between w-full">
                <div className="bg-primary-50 rounded-full p-1 self-start">
                  <div className="bg-primary-100 rounded-full p-1">
                    <FiFile className="text-primary-600 cursor-pointer" />
                  </div>
                </div>
                <div className="flex flex-col gap-1 flex-1 items-start">
                  <span className="text-sm font-semibold">
                    {file?.file_name ?? "Document"}
                  </span>
                  <span className="text-sm">
                    {Math.round(file?.file_size / 1000)}KB
                  </span>
                  <button
                    className="text-primary-600 mt-2 cursor-pointer text-sm font-semibold"
                    onClick={() => {
                      const fileId = file?.fileId ?? file?.id ?? file?.file_id;
                      if (fileId) {
                        setIdFile(fileId);
                      } else {
                        window.open(file?.url, "_blank");
                      }
                    }}
                  >
                    Buka Dokumen
                  </button>
                </div>
                <FiDownloadCloud
                  onClick={() => {
                    const fileId = file?.fileId ?? file?.id ?? file?.file_id;
                    if (fileId) {
                      setIdFile(fileId);
                    } else {
                      window.open(file?.url, "_blank");
                    }
                  }}
                  className="text-gray-600 cursor-pointer"
                  size={20}
                />
              </div>
            ) : (
              <Dropzone
                onUpload={(e) => onChange(e)}
                value={value}
                invalid={invalid}
                error={error}
              />
            )}
          </div>
        </div>
      )}
    />
  );
};

const Dropzone = ({
  onUpload,
  maxSize = 10000000,
  onReject,
  role,
  isDisabled,
  value,
  invalid,
  error,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const [isError, setIsError] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
    },
    maxSize,
    onDropAccepted: (acceptedFiles) => {
      setIsError(false);
      onUpload(acceptedFiles);
    },
    onDropRejected: (rejectedFiles) => {
      if (onReject) {
        onReject(rejectedFiles);
      }
      setIsError(true);
    },
    onDragOver: () => {
      setIsDragging(true);
    },
    onDragLeave: () => {
      setIsDragging(false);
    },
    disabled: isDisabled,
  });

  if (value) {
    return (
      <div className="p-4 bg-white border-gray-200 border-[1px] rounded-lg relative flex flex-col items-center gap-4">
        <FiFile className="text-gray-400" size={30} />
        <BiXCircle
          className="text-red-600 absolute -right-2 -top-2 cursor-pointer"
          size={20}
          onClick={() => {
            onUpload("");
          }}
        />

        {value?.[0]?.name ?? ""}
      </div>
    );
  }

  return (
    <div
      className="flex-1 items-center flex flex-col cursor-pointer"
      {...getRootProps()}
    >
      <div className="bg-gray-50 p-2 rounded-full inline-block">
        <div className="bg-gray-100 p-2 rounded-full">
          <FiUploadCloud
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
          />
        </div>
      </div>
      <div className="text-center mt-2">
        <p>
          <span className="text-primary-600 font-bold">Klik untuk upload</span>{" "}
          atau seret dan lepas kesini
        </p>
        <p className="text-sm">PNG, JPG, PDF, Docx up to 10MB</p>
        <input
          type="file"
          className="hidden"
          onChange={(e) => onUpload(e.target.files)}
          {...getInputProps()}
        />
      </div>
      {isError && (
        <span className="text-xs text-error-600 mt-2">
          Maksimal ukuran file {maxSize / 1000000}MB
        </span>
      )}
      {invalid && (
        <span className="text-xs text-error-600 mt-2">{error?.message}</span>
      )}
    </div>
  );
};

export default FilePickerForm;
