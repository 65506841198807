import React, { useCallback, useEffect, useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { FiCheckCircle, FiPlus } from "react-icons/fi";
import { debounce, map } from "lodash";

import { useDeleteGeneralLedger, useGeneralLedger } from "hooks/generalLedger";

import { ConfirmationModal, InformationModal } from "components/Modal";
import {
  Breadcrumbs,
  Button,
  Input,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import moment from "moment";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { CustomDatepickerForm } from "components/HookForm";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { HiOutlineEye } from "react-icons/hi";

const GeneralLedger = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const deleteGeneralLedger = useDeleteGeneralLedger();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [openPopupSuccessDelete, setOpenPopupSuccessDelete] = useState(false);

  const [selectedData, setSelectedData] = useState(null);

  const links = [
    {
      label: "Jurnal Umum",
    },
  ];

  const filterMethods = useForm({
    mode: "onChange",
    defaultValues: { start_date: "", end_date: "" },
  });
  const filterWatch = useWatch({ control: filterMethods.control });

  const {
    data: list,
    isLoading,
    refetch,
  } = useGeneralLedger([
    "mj",
    {
      page: newPage,
      size: newLimit,
      start_date: filterWatch.start_date
        ? moment(filterWatch.start_date).format("YYYY-MM-DD")
        : "",
      end_date: filterWatch.end_date
        ? moment(filterWatch.end_date).format("YYYY-MM-DD")
        : "",
      sort: "transaction_date",
      order: "desc",
    },
  ]);

  // useEffect(() => {
  //   if (filterWatch.start_date && filterWatch.end_date) {
  //     setStartDate(filterWatch.start_date);
  //     setEndDate(filterWatch.end_date);
  //   }
  // }, [filterWatch.start_date, filterWatch.end_date]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const onTriggerDelete = (data) => {
    setSelectedData(data);
    setOpenPopupDelete(true);
  };

  const onHandleDelete = () => {
    deleteGeneralLedger.mutate(
      { id: selectedData?.manual_journal_id },
      {
        onSuccess: () => {
          setSelectedData(null);
          setOpenPopupDelete(false);
          setOpenPopupSuccessDelete(true);
          refetch();
        },
      }
    );
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(list?.currentPage) * parseInt(list?.size) -
          parseInt(list?.size) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "transaction_date",
      title: "Tanggal Transaksi",
      dataIndex: "transaction_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "total_debits",
      title: "Total Debet",
      dataIndex: "total_debits",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value, data, index) => (value ? value : "-"),
    },
    {
      id: "total_credits",
      title: "Total Kredit",
      dataIndex: "total_credits",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value, data, index) => (value ? value : "-"),
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="flex items-center justify-center lg:w-[20%] w-full">

          {roleAccess?.canUpdate && (
            <HiOutlineEye
              className="text-gray-600 cursor-pointer"

              size={20}
              onClick={() => navigate("/edit-general-ledger?readonly=true", { state: data })}
            />
          )}
          {/* {roleAccess?.canDelete && (
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onTriggerDelete(data)}
            />
          )} */}
        </div>
      ),
    },
  ];

  const renderAllModal = () => (
    <>
      <ConfirmationModal
        open={openPopupDelete}
        onClose={() => setOpenPopupDelete(false)}
        onSubmit={onHandleDelete}
        text={"Apakah Anda yakin akan menghapus data ini?"}
      />
      <InformationModal open={openPopupSuccessDelete} hideHeader>
        <div className="flex items-center justify-center flex-col space-y-4 py-4">
          <IoIosCheckmarkCircleOutline className="text-[100px]" />
          <div className="text-center space-y-2 pb-4">
            <div className="font-bold text-2xl">Data berhasil Dihapus</div>
          </div>
          <Button
            onClick={() => setOpenPopupSuccessDelete(false)}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1 w-56"
          >
            Oke
          </Button>
        </div>
      </InformationModal>
    </>
  );

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">{"Daftar Jurnal Umum"}</TitleText>
        {roleAccess?.canCreate && (
          <Button
            startIcon={<FiPlus />}
            onClick={() => navigate("/add-general-ledger")}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          >
            Tambah
          </Button>
        )}
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <div className="space-x-5 flex items-center">
              {/* <SearchInput
                className="flex-1"
                placeholder="Cari"
                onChange={onSearch}
              />
              <Input
                className="flex-1"
                placeholder="dd/mm/yyyy"
                onChange={onSearch}
              /> */}
              <FormProvider {...filterMethods}>
                <CustomDatepickerForm
                  name={`start_date`}
                  className={`py-2 px-4 border w-full rounded-md`}
                  placeholder={"Tanggal Mulai"}
                />
                <CustomDatepickerForm
                  name={`end_date`}
                  className={`py-2 px-4 border w-full rounded-md`}
                  placeholder={"Tanggal Berakhir"}
                />
              </FormProvider>
            </div>
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={list?.result}
              isLoading={isLoading}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: list?.currentPage,
                limit: list?.size,
                total: list?.totalItems,
                previous_pages: list?.currentPage > 1,
                next_pages: !(list?.currentPage === list?.totalPages),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralLedger;
