import { Breadcrumbs, Button, SearchInput, Table, TitleText } from "components";
import { usePaymentFDBQuery } from "hooks/cash";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { useNavigate } from "react-router";
import StatusBadgeExecution from "./StatusBadge/execution";
import { useSelector } from "react-redux";
import { convertToIDR } from "helpers";

const PaymentExecutionFDB = () => {
  const navigate = useNavigate();

  const { oidc_user } = useSelector(({ oidc }) => oidc);

  const role = useMemo(
    () => JSON.parse(oidc_user?.profile?.Groups)?.[0]?.toLowerCase(),
    [oidc_user]
  );

  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [statusFilter, setStatusFilter] = useState(null);

  const { data: expenses } = usePaymentFDBQuery([
    "payment-fdb",
    {
      role,
      page: newPage,
      size: newLimit,
      search: keyword,
      status: statusFilter?.value,
      order: "asc",
      sort: "status",
    },
  ]);

  const links = [
    {
      label: "Daftar Pembayaran FDB",
    },
  ];

  const renderStatusAction = (data) => {
    switch (data?.status) {
      case "Menunggu Pembayaran":
      case "Pending":
        return (
          <>
            <Button
              onClick={() =>
                navigate(`/payment-request-fdb/detail/${data?.id}?action=pay`)
              }
              className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
            >
              Bayar
            </Button>
          </>
        );
      case "Sedang diproses":
        return (
          <>
            <Button
              onClick={() =>
                navigate(`/payment-request-fdb/detail/${data?.id}?action=pay`)
              }
              className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
            >
              Bayar
            </Button>
            <HiOutlineEye
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate(`/payment-request-fdb/detail/${data?.id}`)}
            />
          </>
        );
      default:
        return (
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => navigate(`/payment-request-fdb/detail/${data?.id}`)}
          />
        );
    }
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "createdAt",
      title: "Tanggal Permohonan",
      dataIndex: "createdAt",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "applicantGroup",
      title: "Kelompok",
      dataIndex: "applicantGroup",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "applicantDistributorInstitution",
      title: "Lembaga Penyalur",
      dataIndex: "applicantDistributorInstitution",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {/* {convertToIDR(value)} */}
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "numberOfApplicants",
      title: "Jumlah anggota yang dibayarkan",
      dataIndex: "numberOfApplicants",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "totalInquiryValue",
      title: "Total Nominal",
      dataIndex: "totalInquiryValue",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {/* {value ? moment(value).format("DD/MM/YYYY") : "-"} */}
          {value ? convertToIDR(value) : "-"}
        </div>
      ),
    },
    {
      id: "inquiryDate",
      title: "Tanggal Pembayaran",
      dataIndex: "inquiryDate",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "status",
      title: "Status",
      dataIndex: "status",
      width: 200,
      className: "overflow-hidden text-ellipsis right-[181px] block shadow-lg",
      columnClassName: "right-[181px] block shadow-lg",
      fixed: "right",
      sortable: true,
      render: (data) => {
        return (
          <div className="space-x-2 flex items-center w-full min-h-12">
            <StatusBadgeExecution
              status={data === "Pending" || data === 'Menunggu Pembayaran' ? "Menunggu Pembayaran" : data}
              role={role}
            />
          </div>
        );
      },
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      fixed: "right",
      render: (value, data, index) => {
        return (
          <div className="space-x-2 flex items-center w-[150px]">
            {renderStatusAction(data)}
          </div>
        );
      },
    },
  ];

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );
  return (
    <>
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">Daftar Pembayaran FDB</TitleText>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4 w-[82vw]">
            <div className="space-x-5 flex items-center">
              <SearchInput placeholder="Cari" onChange={onSearch} />
              {/* <CustomSelect
                value={statusFilter}
                optionsData={[]}
                label="Jenis Transaksi"
                placeholder="Jenis Transaksi"
                className="w-1/6"
                onChange={(value) => {
                  setStatusFilter(value);
                }}
              /> */}
              {/* <CustomSelect
                value={statusFilter}
                optionsData={[]}
                label="Status"
                placeholder="Status"
                className="w-1/2"
                onChange={(value) => {
                  setStatusFilter(value);
                }}
              /> */}
              {/* <CustomDatepickerForm
                name="transaction_date"
                placeholder={"dd/mm/yyyy"}
                onChange={(value) => console.log(value)}
                
              /> */}
            </div>
            <Table
              bordered
              stripped
              layout="auto"
              className="w-full"
              columns={tableColumns}
              dataSource={expenses?.result ?? []}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: expenses?.currentPage,
                limit: expenses?.size,
                total: expenses?.totalItems,
                previous_pages: expenses?.currentPage > 1,
                next_pages: !(expenses?.currentPage === expenses?.totalPages),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentExecutionFDB;
