import { createSlice } from "@reduxjs/toolkit";
import { AUTH } from "services";

const initialState = {
  token: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    removeToken: (state, action) => {
      state.token = action.payload;
    },
    logIn: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    logInSuccess: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.errorMessage = null;
      state.token = action?.payload?.data?.token;
    },
    logInFailed: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = action?.payload?.message;
    },
  },
});

// this is for dispatch
export const { removeToken, logIn, logInSuccess, logInFailed } =
  authSlice.actions;

export const signIn = (params) => {
  return async (dispatch, getState) => {
    dispatch(logIn());
    setTimeout(() => {
      const data = { data: { token: "fakeToken" } };
      dispatch(logInSuccess(data));
    }, 1500);
    // try {
    //   const response = await AUTH.signIn(params);
    //   const data = response?.data;
    //   if (data?.meta?.status) {
    //     dispatch(logInSuccess(data));
    //   } else {
    //     dispatch(logInFailed(data));
    //   }
    // } catch (error) {
    //   dispatch(logInFailed(error.response));
    // }
  };
};

// this is for configureStore
export default authSlice.reducer;
