import { AiOutlineTransaction } from "react-icons/ai";
import {
  FaChartBar,
  FaChartLine,
  FaMoneyBillAlt,
  FaMoneyBillWave,
} from "react-icons/fa";
import { FaMoneyBillTransfer, FaMoneyBillTrendUp } from "react-icons/fa6";
import { FiSettings } from "react-icons/fi";
import { LuClipboardList } from "react-icons/lu";
import {
  MdAccountBalance,
  MdEditDocument,
  MdGroups,
  MdOutlineDashboard,
} from "react-icons/md";
import { PiNewspaperClipping, PiNoteLight } from "react-icons/pi";

const MENU_ITEMS = [
  {
    icon: <MdOutlineDashboard className="text-white w-[18px] h-[20px]" />,
    id: "dashboard",
    label: "Dashboard Anggaran",
    path: "/dashboard",
  },
  {
    icon: <FaMoneyBillAlt className="text-white w-[18px] h-[20px]" />,
    id: "down-payment-activity",
    label: "Uang Muka",
    path: "/down-payment-activity",
  },
  {
    icon: <AiOutlineTransaction className="text-white w-[18px] h-[20px]" />,
    id: "direct-transactions",
    label: "Transaksi Langsung (LS)",
    path: "/direct-transactions",
  },
  {
    icon: <FaMoneyBillWave className="text-white w-[18px] h-[20px]" />,
    id: "operational-advances",
    label: "Uang Muka Operasional (UMO)",
    path: "/operational-advances",
  },
  {
    icon: <FaMoneyBillTransfer className="text-white w-[18px] h-[20px]" />,
    id: "receiving",
    label: "Penerimaan",
    path: "/receiving",
    child: [
      {
        id: "cash-receiving",
        label: "Uang Masuk",
        path: "/cash-receiving",
      },
      {
        id: "bank-cash-flow-receipt",
        label: "Uang Keluar",
        path: "/bank-cash-flow/receipt",
      },
    ],
  },
  {
    icon: <FaMoneyBillTrendUp className="text-white w-[18px] h-[20px]" />,
    id: "expense",
    label: "Pengeluaran",
    path: "/expense",
    child: [
      {
        id: "payment-request",
        label: "Permintaan Pembayaran",
        path: "/payment-request",
      },
      {
        id: "cash-flow",
        label: "Kas Tunai",
        path: "/cash-flow",
      },
      {
        id: "bank-cash-flow-expense",
        label: "Uang Keluar",
        path: "/bank-cash-flow/expense",
      },
    ],
  },
  {
    icon: <FaMoneyBillAlt className="text-white w-[18px] h-[20px]" />,
    id: "payment-request-fdb",
    label: "Pembayaran FDB",
    path: "/payment-request-fdb",
  },
  {
    icon: <FaChartLine className="text-white w-[18px] h-[20px]" />,
    id: "coa-project",
    label: "COA Project",
    path: "/coa-project",
  },
  {
    icon: <FaChartBar className="text-white w-[18px] h-[20px]" />,
    id: "chart-of-account",
    label: "Chart of Account",
    path: "/chart-of-account",
  },
  {
    icon: <LuClipboardList className="text-white w-[18px] h-[20px]" />,
    id: "general-ledger",
    label: "Jurnal Umum",
    path: "/general-ledger",
  },
  {
    icon: <PiNoteLight className="text-white w-[18px] h-[20px]" />,
    id: "transaction-journal-mapping",
    label: "Mapping Jurnal Transaksi",
    path: "/transaction-journal-mapping",
  },
  {
    icon: <MdGroups className="text-white w-[18px] h-[20px]" />,
    id: "authority",
    label: "Mapping Daftar Kewenangan",
    path: "/authority",
  },
  {
    icon: <PiNewspaperClipping className="text-white w-[18px] h-[20px]" />,
    id: "budget",
    label: "Daftar Anggaran",
    path: "/budget",
  },
  {
    icon: <MdEditDocument className="text-white w-[18px] h-[20px]" />,
    id: "budget-revision",
    label: "Revisi Anggaran",
    path: "/budget-revision",
  },
  {
    icon: <MdAccountBalance className="text-white w-[18px] h-[20px]" />,
    id: "account-request",
    label: "Daftar Permintaan Rekening",
    path: "/account-request",
  },
  {
    icon: <MdAccountBalance className="text-white w-[18px] h-[20px]" />,
    id: "finance-report",
    label: "Laporan Keuangan",
    path: "/finance-report",
  },
  {
    icon: <MdAccountBalance className="text-white w-[18px] h-[20px]" />,
    id: "reconciliation",
    label: "Rekonsialisasi Pokok & Bunga",
    path: "/reconciliation-list",
  },
  {
    icon: <MdAccountBalance className="text-white w-[18px] h-[20px]" />,
    id: "reconciliation-accounting",
    label: "Rekonsiliasi",
    path: "/reconciliation-accounting",
  },
  {
    icon: <MdAccountBalance className="text-white w-[18px] h-[20px]" />,
    id: "project-account",
    label: "Daftar Rekening Proyek",
    path: "/project-account",
  },
  {
    icon: <MdAccountBalance className="text-white w-[18px] h-[20px]" />,
    id: "request-project-payment",
    label: "Daftar Permohonan Pembayaran Proyek",
    path: "/request-project-payment",
  },
  {
    icon: <MdAccountBalance className="text-white w-[18px] h-[20px]" />,
    id: "project-payment",
    label: "Daftar Pembayaran Proyek",
    path: "/project-payment",
  },
  // {
  //   icon: <LuClipboardList className="text-white w-[35px] h-[35px]" />,
  //   id: "write-off-request",
  //   label: "Daftar Permintaan Hapus Bukuan Tidak Hapus Tagih",
  //   path: "/write-off-request",
  // },
  {
    icon: <FiSettings />,
    id: "general-settings",
    label: "General Settings",
    path: "/general-settings",
    child: [
      {
        id: "general-settings-bank",
        label: "Bank",
        path: "/general-settings/bank",
      },
      {
        id: "general-settings-deposit",
        label: "Deposito",
        path: "/general-settings/deposit",
      },
    ],
  },
];

export default MENU_ITEMS;
