import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { FiPlus } from "react-icons/fi";
import { find, first, map, sumBy } from "lodash";

import { BottomFormAction, Breadcrumbs, Table } from "components";
import {
  CustomDatepickerForm,
  CustomSelectForm,
  InputForm,
  SelectBudgetListForm,
  SelectCoaByLevelForm,
} from "components/HookForm";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useManualJournalDetail,
  usePostManualJournal,
  usePutManualJournal,
} from "hooks/manualJournal";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { CgClose } from "react-icons/cg";
import moment from "moment";
import { FormModal } from "components/Modal";

const DEFAULT_VALUE_FORM = {
  transaction_date: "",
  notes: "",
  debits_account_name: "",
  debits_description: "",
  debits: "",
  credits_account_name: "",
  credits_description: "",
  credits: "",
};

const FORM_TYPE = ["add", "edit"];
const LOCALE_FORM = {
  add: {
    title: "Tambah Jurnal Manual",
  },
  edit: {
    title: "Detail Jurnal Manual",
  },
};

const ManualJournalForm = () => {
  const location = useLocation();
  const isReadonly = location?.search?.includes("readonly");
  const navigate = useNavigate();

  const createManualJournal = usePostManualJournal();
  const updateManualJournal = usePutManualJournal();

  const queryClient = useQueryClient();

  const formType = useMemo(
    () => find(FORM_TYPE, (type) => location.pathname.includes(type)),
    []
  );

  const links = [
    {
      path: "/general-ledger",
      label: "Jurnal Umum",
    },
    {
      label: LOCALE_FORM[formType]?.title,
    },
  ];

  const { data: detail } = useManualJournalDetail([
    "mj_detail",
    location?.state?.manual_journal_id,
  ]);

  const schema = yup.object().shape({
    // is_parent: yup.boolean().required("Posisi akun baru wajib diisi"),
  });

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      transaction_date: "",
      notes: "",
      accounts: [
        {
          account_selected: null,
          account_selected_temp: null,
          account: null,
          activity_output_detail: null,
          output_activity: null,
          component: null,
          description: "",
          debet: "",
          kredit: "",
        },
      ],
    },
  });

  const accounts = useWatch({ control: methods.control, name: "accounts" });

  const { reset } = methods;

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "accounts",
  });

  useEffect(() => {
    if (first(detail)?.manual_journal_id) {
      reset({
        transaction_date: first(detail)?.transaction_date
          ? new Date(first(detail)?.transaction_date)
          : "",
        accounts: map(detail, (row) => {
          return {
            transaction_id: row?.transaction_id,
            account_selected: {
              value: row?.debits
                ? row?.debits_account_id
                : row?.credits_account_id,
              label: row?.debits
                ? row?.debits_account_name
                : row?.credits_account_name,
              coa_name: row?.debits
                ? row?.debits_description
                : row?.credits_description,
              operational_component_name: row?.debits
                ? row?.debits_description
                : row?.credits_description,
            },
            account_selected_temp: {
              value: row?.debits
                ? row?.debits_account_id
                : row?.credits_account_id,
              label: row?.debits
                ? row?.debits_account_name
                : row?.credits_account_name,
              coa_name: row?.debits
                ? row?.debits_description
                : row?.credits_description,
              operational_component_name: row?.debits
                ? row?.debits_description
                : row?.credits_description,
            },
            account:
              row?.level === 9
                ? {
                    value: row?.debits
                      ? row?.debits_account_id
                      : row?.credits_account_id,
                    label: row?.debits
                      ? row?.debits_account_name
                      : row?.credits_account_name,
                    coa_name: row?.debits
                      ? row?.debits_description
                      : row?.credits_description,
                    operational_component_name: row?.debits
                      ? row?.debits_description
                      : row?.credits_description,
                  }
                : null,
            activity_output_detail:
              row?.level === 3
                ? {
                    value: row?.debits
                      ? row?.debits_account_id
                      : row?.credits_account_id,
                    label: row?.debits
                      ? row?.debits_account_name
                      : row?.credits_account_name,
                    coa_name: row?.debits
                      ? row?.debits_description
                      : row?.credits_description,
                    operational_component_name: row?.debits
                      ? row?.debits_description
                      : row?.credits_description,
                  }
                : null,
            output_activity:
              row?.level === 5
                ? {
                    value: row?.debits
                      ? row?.debits_account_id
                      : row?.credits_account_id,
                    label: row?.debits
                      ? row?.debits_account_name
                      : row?.credits_account_name,
                    coa_name: row?.debits
                      ? row?.debits_description
                      : row?.credits_description,
                    operational_component_name: row?.debits
                      ? row?.debits_description
                      : row?.credits_description,
                  }
                : null,
            component:
              row?.level === 8
                ? {
                    value: row?.debits
                      ? row?.debits_account_id
                      : row?.credits_account_id,
                    label: row?.debits
                      ? row?.debits_account_name
                      : row?.credits_account_name,
                    coa_name: row?.debits
                      ? row?.debits_description
                      : row?.credits_description,
                    operational_component_name: row?.debits
                      ? row?.debits_description
                      : row?.credits_description,
                  }
                : null,
            description: row?.debits
              ? row?.debits_description
              : row?.credits_description,
            debet: row?.debits,
            credit: row?.credits,
          };
        }),
      });
    }
    return;
  }, [detail]);

  useEffect(() => {
    const totalDebet = sumBy(accounts, (account) =>
      parseInt(account?.debet ? account?.debet : 0)
    );
    const totalCredit = sumBy(accounts, (account) =>
      parseInt(account?.credit ? account?.credit : 0)
    );
    methods.resetField("total_debet", { defaultValue: totalDebet });
    methods.resetField("total_credit", { defaultValue: totalCredit });
  }, [accounts]);

  useEffect(() => {
    const totalDebet = sumBy(accounts, (account) =>
      parseInt(account?.debet ? account?.debet : 0)
    );
    const totalCredit = sumBy(accounts, (account) =>
      parseInt(account?.credit ? account?.credit : 0)
    );
    methods.resetField("total_debet", { defaultValue: totalDebet });
    methods.resetField("total_credit", { defaultValue: totalCredit });
  }, [accounts]);

  const tableColumns = [
    {
      id: "account",
      title: "Akun",
      dataIndex: "account",
      render: (value, data, key) => (
        <ContainerSelectCoa key={data.id} name={`accounts.${key}`} />
      ),
    },
    {
      id: "description",
      title: "Deskripsi",
      dataIndex: "description",
      render: (value, data, key) => (
        <>
          <InputForm
            key={data.id}
            name={`accounts.${key}.description`}
            className="w-full"
            disabled
          />
        </>
      ),
    },
    {
      id: "debet",
      title: "Debet",
      dataIndex: "debet",
      render: (value, data, key) => (
        <InputForm
          key={data.id}
          name={`accounts.${key}.debet`}
          className="w-full"
          disabled={isReadonly || methods.watch(`accounts.${key}.credit`)}
        />
      ),
    },
    {
      id: "credit",
      title: "Kredit",
      dataIndex: "credit",
      render: (value, data, key) => (
        <InputForm
          key={data.id}
          name={`accounts.${key}.credit`}
          className="w-full"
          disabled={isReadonly || methods.watch(`accounts.${key}.debet`)}
        />
      ),
    },
    
  ];

  if(!isReadonly) {
    tableColumns.push(
      {
        id: "action",
        title: "",
        dataIndex: "action",
        className: "w-[0%]",
        render: (value, data, key) => (
          <>
            {key === 0 ? (
              <button
                className="bg-primary-600 p-3 rounded"
                onClick={() =>
                  append({
                    account: null,
                    description: "",
                    debet: "",
                    kredit: "",
                  })
                }
              >
                <FiPlus className="text-white" />
              </button>
            ) : (
              <button
                className="bg-error-600 p-3 rounded"
                onClick={() => remove(key)}
              >
                <CgClose className="text-white" />
              </button>
            )}
          </>
        ),
      },
    )
  }

  const onCancel = () => {
    navigate(-1);
  };

  const onSubmit = (payload) => {
    if (payload) {
      let params = {
        data: map(payload?.accounts, (account) => {
          if (account?.debet) {
            return {
              transaction_date: payload?.transaction_date
                ? moment(payload?.transaction_date).format("YYYY-MM-DD")
                : "",
              debits_account_id: account?.account_selected?.id,
              debits_account_name: account?.account_selected?.label,
              debits_description: account?.account_selected?.name,
              debits: account?.debet,
              credits_account_id: "",
              credits_account_name: "",
              credits_description: "",
              credits: 0,
              notes: "",
              level: account?.account_selected?.level,
            };
          } else {
            return {
              transaction_date: payload?.transaction_date
                ? moment(payload?.transaction_date).format("YYYY-MM-DD")
                : "",
              debits_account_id: "",
              debits_account_name: "",
              debits_description: "",
              debits: 0,
              credits_account_id: account?.account_selected?.id,
              credits_account_name: account?.account_selected?.label,
              credits_description: account?.account_selected?.name,
              credits: account?.credit,
              notes: "",
              level: account?.account_selected?.level,
            };
          }
        }),
      };
      if (location?.state?.manual_journal_id) {
        params = {
          payload: {
            data: map(payload?.accounts, (account) => {
              if (account?.debet) {
                return {
                  transaction_date: payload?.transaction_date
                    ? moment(payload?.transaction_date).format("YYYY-MM-DD")
                    : "",
                  debits_account_id: account?.account_selected?.id,
                  debits_account_name: account?.account_selected?.label,
                  debits_description: account?.account_selected?.name,
                  debits: account?.debet,
                  credits_account_id: "",
                  credits_account_name: "",
                  credits_description: "",
                  credits: 0,
                  notes: "",
                  level: account?.account_selected?.level,
                  transaction_id: account?.transaction_id ?? null,
                };
              } else {
                return {
                  transaction_date: payload?.transaction_date
                    ? moment(payload?.transaction_date).format("YYYY-MM-DD")
                    : "",
                  debits_account_id: "",
                  debits_account_name: "",
                  debits_description: "",
                  debits: 0,
                  credits_account_id: account?.account_selected?.id,
                  credits_account_name: account?.account_selected?.label,
                  credits_description: account?.account_selected?.name,
                  credits: account?.credit,
                  credits: account?.credit,
                  notes: "",
                  level: account?.account_selected?.level,
                  transaction_id: account?.transaction_id ?? null,
                };
              }
            }),
          },
          id: location?.state?.manual_journal_id,
        };
        updateManualJournal.mutate(params, {
          onSuccess: (data, variables) => {
            methods.reset(DEFAULT_VALUE_FORM);
            navigate("/general-ledger");
            enqueueSnackbar({
              message: data?.message ?? "Success",
              variant: "success",
            });
            queryClient.invalidateQueries("mj");
          },
        });
      } else {
        createManualJournal.mutate(params, {
          onSuccess: (data, variables) => {
            methods.reset(DEFAULT_VALUE_FORM);
            navigate("/general-ledger");
            enqueueSnackbar({
              message: data?.message ?? "Success",
              variant: "success",
            });
            queryClient.invalidateQueries("mj");
          },
        });
      }
    }
  };

  const renderAllModal = () => {
    return null;
  };

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="space-y-4">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="space-y-3">
                <div className="text-lg font-semibold">Jurnal Umum</div>
                <CustomDatepickerForm
                  name={`transaction_date`}
                  className={`py-2 px-4 border w-full rounded-md`}
                  label={"Tanggal Transaksi"}
                  placeholder={"Tanggal Transaksi"}
                  required={true}
                  disabled={isReadonly}
                />
              </div>
            </div>
          </div>
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px] p-4">
            <Table
              bordered
              stripped
              layout="fixed"
              columns={tableColumns}
              dataSource={fields}
              hidePagination={true}
            />
          </div>
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border flex flex-row items-center justify-end p-4">
            <div className="flex flex-row items-center space-x-4">
              <InputForm
                name={"total_debet"}
                label="Total Debet"
                className="w-full"
                disabled
              />
              <InputForm
                name={"total_credit"}
                label="Total Kredit"
                className="w-full"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="h-24" />
        <BottomFormAction
          isHideSubmitButton
          onCancel={onCancel}
          onSubmit={methods.handleSubmit(onSubmit)}
          submitBtnDisabled={
            methods.watch("total_debet") !== methods.watch("total_credit")
          }
        />
      </FormProvider>
    </>
  );
};

export default ManualJournalForm;

const ContainerSelectCoa = ({ name }) => {
  const location = useLocation();
  const isReadonly = location?.search?.includes("readonly");
  const { control, watch, resetField, setValue } = useFormContext();
  const coa = useWatch({ control, name: `${name}.account_selected` });

  const [accountModal, setAccountModal] = useState(false);

  useEffect(() => {
    if (coa) {
      resetField(`${name}.description`, {
        defaultValue: coa.description,
      });
    }
  }, [coa]);

  return (
    <div>
      <CustomSelectForm
        name={`${name}.account_selected`}
        className="w-full"
        menuPortalTarget={document.body}
        onFocus={() => setAccountModal(true)}
        menuIsOpen={false}
        isDisabled={isReadonly}
      />
      <FormModal
        title="Pilih Akun"
        open={accountModal}
        onClose={() => setAccountModal(false)}
        onSubmit={() => {
          setAccountModal(false);
          setValue(
            `${name}.account_selected`,
            watch(`${name}.account_selected_temp`)
          );
        }}
      >
        <div className="space-y-4">
          <SelectCoaByLevelForm
            name={`${name}.activity_output_detail`}
            className="w-full"
            label={"Kegiatan Rencana Output"}
            level={3}
            isClearable
            isDisabled={
              !watch(`${name}.activity_output_detail`)?.value &&
              !watch(`${name}.account_selected_temp`)?.value
                ? false
                : watch(`${name}.account_selected_temp`)?.value !==
                  watch(`${name}.activity_output_detail`)?.value
            }
            onSelect={(value) => {
              setValue(`${name}.account_selected_temp`, value);
            }}
          />
          <SelectCoaByLevelForm
            name={`${name}.output_activity`}
            className="w-full"
            label={"Rencana Output"}
            level={5}
            isClearable
            isDisabled={
              !watch(`${name}.output_activity`)?.value &&
              !watch(`${name}.account_selected_temp`)?.value
                ? false
                : watch(`${name}.account_selected_temp`)?.value !==
                  watch(`${name}.output_activity`)?.value
            }
            onSelect={(value) =>
              setValue(`${name}.account_selected_temp`, value)
            }
          />
          <SelectCoaByLevelForm
            name={`${name}.component`}
            className="w-full"
            label={"Komponen"}
            level={8}
            isClearable
            isDisabled={
              !watch(`${name}.component`)?.value &&
              !watch(`${name}.account_selected_temp`)?.value
                ? false
                : watch(`${name}.account_selected_temp`)?.value !==
                  watch(`${name}.component`)?.value
            }
            onSelect={(value) =>
              setValue(`${name}.account_selected_temp`, value)
            }
          />
          <SelectCoaByLevelForm
            name={`${name}.account`}
            className="w-full"
            label={"Akun"}
            level={9}
            isClearable
            isDisabled={
              !watch(`${name}.account`)?.value &&
              !watch(`${name}.account_selected_temp`)?.value
                ? false
                : watch(`${name}.account_selected_temp`)?.value !==
                  watch(`${name}.account`)?.value
            }
            onSelect={(value) =>
              setValue(`${name}.account_selected_temp`, value)
            }
          />
        </div>
      </FormModal>
    </div>
  );
};
