import React, { useCallback, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { FiCheckCircle } from "react-icons/fi";
import { debounce } from "lodash";

import { useAccountRequest } from "hooks/accountRequest";

import { ConfirmationModal, InformationModal } from "components/Modal";
import {
  Breadcrumbs,
  Button,
  Input,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import moment from "moment";
import { BiPencil } from "react-icons/bi";

export const transformCurrencyString = (currencyString) => {
  if (!currencyString) return '';
  
  // UUID regex pattern
  const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  
  return uuidPattern.test(currencyString) ? 'IDR' : currencyString;
};

const AccountRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [openPopupSuccessDelete, setOpenPopupSuccessDelete] = useState(false);

  const [openDetail, setOpenDetail] = useState(false);
  const [selectedData, setSelectedData] = useState(false);

  const links = [
    {
      label: "Daftar Permintaan Rekening",
    },
  ];

  const { data: list, isLoading } = useAccountRequest([
    "ar",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
    },
  ]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(list?.currentPage) * parseInt(list?.size) -
          parseInt(list?.size) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "request_date",
      title: "Tanggal Request",
      dataIndex: "request_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "donor_name",
      title: "Nama Donor",
      dataIndex: "donor_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "currency",
      title: "Mata Uang",
      dataIndex: "currency",
      width: 540,
      className: "overflow-hidden text-ellipsis w-[200px]",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[200px]">
          {value ? transformCurrencyString(value) : "-"}
        </div>
      ),
    },
    {
      id: "account_number",
      title: "Nomor Rekening",
      dataIndex: "account_number",
      width: 540,
      className: "overflow-hidden text-ellipsis w-[200px]",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[200px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "account_name",
      title: "Nama Rekening",
      dataIndex: "account_name",
      width: 540,
      className: "overflow-hidden text-ellipsis w-[200px]",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[200px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "bank_name",
      title: "Nama Bank",
      dataIndex: "bank_name",
      width: 540,
      className: "overflow-hidden text-ellipsis w-[200px]",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[200px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "status",
      title: "Status",
      dataIndex: "status",
      width: 540,
      className: "overflow-hidden text-ellipsis w-[200px]",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[200px]">
          {value ? (value === "1" ? "Sudah Dibuat" : "Baru") : "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: () => (
        <div className="flex items-center justify-center w-full">Aksi</div>
      ),
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis w-48",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="flex items-center justify-center space-x-4 w-full">
          {data?.account_number ? (
            <>
              {roleAccess?.canView && (
                <HiOutlineEye
                  className="text-gray-600 cursor-pointer"
                  size={20}
                  onClick={() => {
                    setSelectedData(data);
                    setOpenDetail(true);
                  }}
                />
              )}
              {roleAccess?.canUpdate && (
                <BiPencil
                  className="text-gray-600 cursor-pointer flex-1"
                  size={20}
                  onClick={() =>
                    navigate("/edit-account-request", {
                      state: { ...data, isEdit: true },
                    })
                  }
                />
              )}
            </>
          ) : (
            <Button
              onClick={() => navigate("/edit-account-request", { state: data })}
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-32 btn-sm"
            >
              Input Rekening
            </Button>
          )}
        </div>
      ),
    },
  ];

  const renderAllModal = () => (
    <>
      <ConfirmationModal
        open={openPopupDelete}
        onClose={() => setOpenPopupDelete(false)}
        onSubmit={async () => {
          setOpenPopupDelete(false);
          setOpenPopupSuccessDelete(true);
        }}
        text={"Apakah Anda yakin akan menghapus data ini?"}
      />
      <InformationModal open={openPopupSuccessDelete} hideHeader>
        <div className="flex items-center justify-center flex-col space-y-6">
          <FiCheckCircle className="text-[100px]" />
          <div>Data berhasil Dihapus</div>
          <Button
            onClick={() => setOpenPopupSuccessDelete(false)}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1"
          >
            Oke
          </Button>
        </div>
      </InformationModal>
      <InformationModal
        title="Detail"
        open={openDetail}
        onClose={() => setOpenDetail(false)}
      >
        <div className="grid grid-cols-2 gap-4">
          <div className="text-sm">
            <label className="font-medium">Nama Donor</label>
            <p>{selectedData?.donor_name ? selectedData?.donor_name : "-"}</p>
          </div>
          <div className="text-sm">
            <label className="font-medium">Negara Donor</label>
            <p>
              {selectedData?.donor_country ? selectedData?.donor_country : "-"}
            </p>
          </div>
          <div className="text-sm">
            <label className="font-medium">Program</label>
            <p>
              {selectedData?.program_name ? selectedData?.program_name : "-"}
            </p>
          </div>

          <div className="col-span-2 bg-gray-300 h-[2px]" />

          <div className="col-span-2">
            <label className="text-lg font-bold">Data Rekening Bank</label>
          </div>

          <div className="text-sm">
            <label className="font-medium">Nomor Rekening Asal</label>
            <p>
              {selectedData?.account_number
                ? selectedData?.account_number
                : "-"}
            </p>
          </div>
          <div className="text-sm">
            <label className="font-medium">Nama Pemilik Rekening</label>
            <p>
              {selectedData?.account_name ? selectedData?.account_name : "-"}
            </p>
          </div>
          <div className="text-sm">
            <label className="font-medium">Mata Uang</label>
            <p>{selectedData?.currency ? selectedData?.currency : "-"}</p>
          </div>
          <div className="text-sm">
            <label className="font-medium">Bank</label>
            <p>{selectedData?.bank_name ? selectedData?.bank_name : "-"}</p>
          </div>
          <div className="text-sm">
            <label className="font-medium">Cabang Bank</label>
            <p>{selectedData?.branch_name ? selectedData?.branch_name : "-"}</p>
          </div>
        </div>
      </InformationModal>
    </>
  );

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">{"Daftar Permintaan Rekening"}</TitleText>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <div className="space-x-5 flex">
              <SearchInput placeholder="Cari" onChange={onSearch} />
            </div>
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={list?.result}
              isLoading={isLoading}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: list?.currentPage,
                limit: list?.size,
                total: list?.totalItems,
                previous_pages: list?.currentPage > 1,
                next_pages: !(list?.currentPage === list?.totalPages),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountRequest;
