import { BiPencil, BiTrash } from "react-icons/bi";
import { FiCheckCircle, FiPlus } from "react-icons/fi";

import {
  Breadcrumbs,
  Button,
  CustomSelect,
  Input,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { ConfirmationModal, InformationModal } from "components/Modal";
import {
  useApprovalSubmission,
  useDeleteSubmission,
  useSubmissionQuery,
} from "hooks/directTransaction";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import StatusBadge from "./StatusBadge";
import { getStatusProperties } from "./util";
import { useSelector } from "react-redux";

const DirectTransaction = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const { oidc_user } = useSelector(({ oidc }) => oidc);

  const role = useMemo(
    () => JSON.parse(oidc_user?.profile?.Groups)?.map((group) => group.toLowerCase()),
    [oidc_user]
  );

  const [selectedData, setSelectedData] = useState(null);
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [openPopupSuccessDelete, setOpenPopupSuccessDelete] = useState(false);
  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [statusFilter, setStatusFilter] = useState(null);

  const { data: submissions, refetch } = useSubmissionQuery([
    "dt-submission",
    {
      role,
      page: newPage,
      limit: newLimit,
      search: keyword,
      status: statusFilter?.value,
    },
  ]);

  const deleteActivity = useDeleteSubmission();
  const approval = useApprovalSubmission();

  const links = [
    {
      label: "Transaksi Langsung",
      path: "/direct-transactions",
    },
    {
      label: "Daftar Pengajuan LS",
    },
  ];

  const onTriggerDelete = (data) => {
    setSelectedData(data);
    setOpenPopupDelete(true);
  };

  const onHandleDelete = () => {
    deleteActivity.mutate(
      { id: selectedData?.id },
      {
        onSuccess: () => {
          refetch();
          setSelectedData(null);
          setOpenPopupDelete(false);
          setOpenPopupSuccessDelete(true);
        },
      }
    );
  };

  const onClosePopupSuccess = () => {
    setOpenPopupSuccessDelete(false);
  };

  const renderStatusAction = (status, data) => {
    switch (data?.status?.action) {
      case "crud":
        return (
          <>
            {roleAccess?.canView && (
              <HiOutlineEye
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() =>
                  navigate(`/direct-transactions/detail/${data.id}`, {
                    state: { role },
                  })
                }
              />
            )}
            {roleAccess?.canUpdate && (
              <BiPencil
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() =>
                  navigate(`/direct-transactions/edit/${data.id}`, {
                    state: { role },
                  })
                }
              />
            )}
            {roleAccess?.canDelete && (
              <BiTrash
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() => onTriggerDelete(data)}
              />
            )}
          </>
        );
      case "detail":
        return (
          <>
            {roleAccess?.canView && (
              <HiOutlineEye
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() =>
                  navigate(`/direct-transactions/detail/${data?.id}`)
                }
              />
            )}
          </>
        );
      case "approval":
        return (
          <Button
            onClick={() =>
              navigate(`/direct-transactions/verification-request/${data?.id}`)
            }
            className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
          >
            Approval
          </Button>
        );
      case "review":
        return (
          <Button
            onClick={
              () => null
              // navigate(
              //   `/direct-transactions/verification-request/${data?.id}`
              // )
            }
            className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
          >
            Review
          </Button>
        );
      case "process":
        return (
          <Button
            onClick={() =>
              navigate(`/direct-transactions/verification-request/${data?.id}`)
            }
            className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
          >
            Proses
          </Button>
        );
      default:
        return (
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => navigate(`/direct-transactions/detail/${data?.id}`)}
          />
        );
    }
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "created_at",
      title: "Tanggal Pengajuan",
      dataIndex: "created_at",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "description",
      title: "Nama Kegiatan",
      dataIndex: "description",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "director",
      title: "Direktorat",
      dataIndex: "directorate",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "account",
      title: "Akun",
      dataIndex: "component_code",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "payment_type",
      title: "Jenis Pembayaran",
      dataIndex: "payment_type",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value === "contractual"
            ? "Kontraktual"
            : value === "non_contractual"
            ? "Non Kontraktual"
            : "Vendor"}
        </div>
      ),
    },
    {
      id: "spj_date",
      title: "Tanggal SPJ",
      dataIndex: "spj_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "type",
      title: "Tipe",
      dataIndex: "type",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "evidence_number",
      title: "No. Bukti",
      dataIndex: "evidence_number",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "status",
      title: "Status",
      dataIndex: "status",
      width: 200,
      className: "overflow-hidden text-ellipsis right-[181px] block shadow-lg",
      columnClassName: "right-[181px] block shadow-lg",
      fixed: "right",
      sortable: true,
      render: (value) => {
        return (
          <div className="space-x-2 flex items-center w-full min-h-12">
            <StatusBadge status={value?.description} role={role} />
          </div>
        );
      },
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      fixed: "right",
      render: (value, data, index) => {
        const status = getStatusProperties(data.status?.description);
        return (
          <div className="space-x-2 flex items-center w-[150px]">
            {renderStatusAction(status, data)}
          </div>
        );
      },
    },
  ];

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  return (
    <>
      <ConfirmationModal
        open={openPopupDelete}
        onClose={() => setOpenPopupDelete(false)}
        onSubmit={onHandleDelete}
        text={"Apakah Anda yakin akan menghapus data ini?"}
      />
      <InformationModal open={openPopupSuccessDelete} hideHeader>
        <div className="flex items-center justify-center flex-col space-y-6">
          <FiCheckCircle className="text-[100px]" />
          <div>Data berhasil Dihapus</div>
          <Button
            onClick={onClosePopupSuccess}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1"
          >
            Oke
          </Button>
        </div>
      </InformationModal>
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">Daftar Pengajuan LS</TitleText>
        {role?.includes("pjk") && roleAccess?.canCreate && (
          <Button
            startIcon={<FiPlus />}
            onClick={() =>
              navigate("/direct-transactions/add", { state: { role } })
            }
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          >
            Tambah Kegiatan
          </Button>
        )}
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4 w-[82vw]">
            <div className="space-x-5 flex items-center">
              <SearchInput placeholder="Cari" onChange={onSearch} />
              {/* <CustomSelect
                value={statusFilter}
                optionsData={activityStatus?.result?.map((status) => ({
                  label: status?.description,
                  value: status?.status,
                }))}
                label="Status"
                placeholder="Status"
                className="w-1/6"
                onChange={(value) => {
                  setStatusFilter(value);
                }}
              /> */}
              <Input className="flex-1" placeholder="dd/mm/yyyy" />
            </div>
            <Table
              bordered
              stripped
              layout="auto"
              className="w-full"
              columns={tableColumns}
              dataSource={submissions?.result}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: submissions?.paginator?.page,
                limit: submissions?.paginator?.limit,
                total: submissions?.paginator?.itemCount,
                previous_pages: submissions?.paginator?.page > 1,
                next_pages: !(
                  submissions?.paginator?.page ===
                  submissions?.paginator?.pageCount
                ),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectTransaction;
