import classNames from "classnames";
import { Breadcrumbs, SearchInput, Table, TitleText } from "components";
import {
  CustomDatepickerForm,
  CustomSelectForm,
  InputForm,
} from "components/HookForm";
import { InformationModal } from "components/Modal";
import {
  useProjectAccountTrxHistoriesQuery,
  useProjectAccountTrxHistoryDetail,
} from "hooks/projectAccount";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { HiOutlineEye } from "react-icons/hi";
import { useParams } from "react-router";

const DetailItem = ({ title, value = "-", className }) => (
  <div className={classNames("flex flex-col gap-2", className)}>
    <span className="text-sm font-semibold">{title}</span>
    <span>{value}</span>
  </div>
);

const ProjectAccountTrxHistory = () => {
  const { id } = useParams();

  const [keyword, setKeyword] = useState("");
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);

  const [modalDetail, setModalDetail] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const filters = useForm({ mode: "all" });

  useEffect(() => {
    handleSearch(filters.watch("search"));
  }, [filters.watch("search")]);

  const handleSearch = useCallback(
    debounce((keyword) => {
      setKeyword(keyword);
    }, 500),
    []
  );

  const { data } = useProjectAccountTrxHistoriesQuery([
    "trx-history-list",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
      date: filters.watch("date") ? moment(filters.watch("date")).format("YYYY-MM-DD") : ""
    },
  ]);

  const { data: detail } = useProjectAccountTrxHistoryDetail(
    ["trx-history-detail", selectedData?.id],
    { enabled: !!selectedData?.id }
  );

  const links = [
    {
      label: "Daftar Rekening Proyek",
      path: "/project-account",
    },
    {
      label: "Detail",
    },
  ];

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className=" overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "transaction_date",
      title: "Tgl transaksi",
      dataIndex: "transaction_date",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "transaction_type",
      title: "Jenis Transaksi",
      dataIndex: "transaction_type",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value
            ? value === 1
              ? "Penerimaan Uang Masuk Dari Donor"
              : value === 2
              ? "Konversi"
              : "Pemindahan Dana Ke Rekening Lain"
            : "-"}
        </div>
      ),
    },
    {
      id: "balance_flow_in",
      title: "Penambahan",
      dataIndex: "balance_flow_in",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "balance_flow_out",
      title: "Pengurangan",
      dataIndex: "balance_flow_out",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "action",
      title: "Aksi",
      dataIndex: "action",
      width: 160,
      sortable: true,
      fixed: "right",
      render: (value, data) => (
        <div className="space-x-2 flex items-center w-full">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => onDetail(data)}
          />
        </div>
      ),
    },
  ];

  const onDetail = (data) => {
    setModalDetail(true);
    setSelectedData(data);
  };

  const renderModal = () => (
    <>
      <InformationModal
        title="Detail"
        open={modalDetail}
        onClose={() => setModalDetail(false)}
        className="bg-white rounded-xl w-2/3"
        childClassName="flex-1 max-h-[70vh] overflow-y-scroll px-8 py-4"
      >
        {selectedData?.transaction_type === 1 && (
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
              <DetailItem
                title="Tanggal Transaksi"
                value={moment(detail?.transaction_date).format("DD/MM/YYYY")}
              />
              <DetailItem
                title="Jenis Transaksi"
                value={"Penerimaan Uang Masuk Dari Donor"}
              />
              <DetailItem
                title="Nomor Rekening"
                value={detail?.account_number}
              />
              <DetailItem title="Nama Rekening" value={detail?.project_account?.account?.account_name} />
              <DetailItem
                title="Nilai Uang Masuk"
                value={detail?.nominal_input}
              />
              <DetailItem title="Mata Uang" value={detail?.project_account?.currency_name} />
            </div>
            <DetailItem title="Deskripsi" value={detail?.description} />
            <DetailItem title="User" value={detail?.project_account?.account?.donor_name} />
          </div>
        )}
        {selectedData?.transaction_type === 2 && (
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-4 gap-4">
              <DetailItem
                title="Tanggal Transaksi"
                value={moment(detail?.transaction_date).format("DD/MM/YYYY")}
              />
              <DetailItem title="Jenis Transaksi" value={"Konversi"} />
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <DetailItem
                title="Nomor Rekening Sumber Konversi"
                value={detail?.account_number}
              />
              <DetailItem title="Nama Rekening" value={detail?.project_account?.account?.account_name} />
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <DetailItem
                title="Nominal Pemindahan"
                value={detail?.nominal_transfer}
              />
              <DetailItem title="Mata Uang" value={detail?.project_account?.currency_name} />
              <DetailItem title="Kurs Konversi" value={detail?.kurs} />
              <DetailItem
                title="Total Hasil Konversi"
                value={detail?.convertion_result}
              />
              <DetailItem
                title="Nomor Rekening Tujuan Konversi"
                value={detail?.target_account_number}
              />
              <DetailItem title="Nama Rekening" value={detail?.project_account?.account?.account_name} />
            </div>
            <DetailItem title="Deskripsi" value={detail?.description} />
            <DetailItem title="User" value={detail?.project_account?.account?.donor_name} />
          </div>
        )}
        {selectedData?.transaction_type === 3 && (
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
              <DetailItem
                title="Tanggal Transaksi"
                value={moment(detail?.transaction_date).format("DD/MM/YYYY")}
              />
              <DetailItem
                title="Jenis Transaksi"
                value={"Pemindahan Dana Ke Rekening Lain"}
              />
              <DetailItem
                title="Nomor Rekening Sumber"
                value={detail?.account_number}
              />
              <DetailItem title="Nama Rekening" value={"-"} />
              <DetailItem
                title="Nilai Uang Masuk"
                value={detail?.nominal_transfer}
              />
              <DetailItem title="Mata Uang" value={detail?.project_account?.currency_name} />
              <DetailItem
                title="Nomor Rekening Tujuan"
                value={detail?.target_account_number}
              />
              <DetailItem title="Nama Rekening" value={"-"} />
            </div>
            <DetailItem title="Deskripsi" value={detail?.description} />
            <DetailItem title="User" value={detail?.project_account?.account?.donor_name} />
          </div>
        )}
      </InformationModal>
    </>
  );

  return (
    <>
      {renderModal()}
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <div className="space-y-4">
        <TitleText className="flex-1">{"Riwayat Transaksi"}</TitleText>
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
          <div className="card-body p-4 space-y-4">
            <FormProvider {...filters}>
              {/* <div className="space-x-4 flex items-center">
                <InputForm
                  name="search"
                  className="flex-1 w-full"
                  placeholder="Cari"
                />
                <CustomDatepickerForm
                  name="date"
                  className="flex-1"
                  placeholder="dd/mm/yyyy"
                  isClearable
                />
              </div> */}
            </FormProvider>
            <Table
              bordered
              stripped
              layout="auto"
              className="w-full"
              columns={tableColumns}
              dataSource={data?.result}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: data?.currentPage,
                limit: data?.size,
                total: data?.totalItems,
                previous_pages: data?.currentPage > 1,
                next_pages: !(data?.currentPage === data?.totalPages),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectAccountTrxHistory;
