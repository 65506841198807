import React, { useCallback, useState } from "react";
import { Breadcrumbs, Button, SearchInput, Table, TitleText } from "components";
import { FiPlus } from "react-icons/fi";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import { useProjectPaymentsQuery } from "hooks/projectPayment";
import { debounce } from "lodash";
import { HiOutlineEye } from "react-icons/hi";
import StatusBadge from "./StatusBadge";
import { convertToIDR } from "helpers";
import moment from "moment";
import { getTransactionTypeProperties } from "./util";

const ProjectPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const [keyword, setKeyword] = useState("");
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);

  const { data } = useProjectPaymentsQuery([
    "project-payments",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
      // order: 'status',
      // sort: 'asc',
    },
  ]);

  const links = [
    {
      label: "Daftar Permintaan Pembayaran",
    },
  ];

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const renderStatusAction = (data) => {
    if (data?.status === 0) {
      return (
        <Button
          onClick={() => navigate(`/project-payment/payment/${data?.id}`)}
          className="flex-1 text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 whitespace-pre-wrap"
        >
          Bayar
        </Button>
      );
    }
    return (
      <HiOutlineEye
        className="text-gray-600 cursor-pointer flex-1"
        size={20}
        onClick={() => navigate(`/project-payment/${data?.id}`)}
      />
    );
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "submission_date",
      title: "Tgl Permintaan",
      dataIndex: "submission_date",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "transaction_type",
      title: "Jenis Pembayaran",
      dataIndex: "transaction_type",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? getTransactionTypeProperties(value) : "-"}
        </div>
      ),
    },
    {
      id: "project_name",
      title: "Project",
      dataIndex: "project_name",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "project_desc",
      title: "Deskripsi",
      dataIndex: "project_desc",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "total_payment",
      title: "Nilai",
      dataIndex: "total_payment",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? convertToIDR(value) : "-"}
        </div>
      ),
    },
    // {
    //   id: "requester",
    //   title: "Requester",
    //   dataIndex: "requester",
    //   width: 340,
    //   className: "overflow-hidden text-ellipsis",
    //   sortable: true,
    //   render: (value) => (
    //     <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
    //       {value ? value : "-"}
    //     </div>
    //   ),
    // },
    {
      id: "payment_date",
      title: "Tgl Pembayaran",
      dataIndex: "payment_date",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "status",
      title: "Status",
      dataIndex: "status",
      width: 340,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-fit">
          <StatusBadge status={value} />
        </div>
      ),
    },
    {
      id: "action",
      title: "Aksi",
      dataIndex: "action",
      width: 160,
      className: "overflow-hidden text-ellipsis block",
      sortable: true,
      render: (value, data) => (
        <div className="space-x-2 flex items-center w-[150px]">
          {renderStatusAction(data)}
        </div>
      ),
    },
  ];

  return (
    <>
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">
          {"Daftar Permohonan Pembayaran Proyek"}
        </TitleText>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4 w-[82vw]">
            {/* <SearchInput placeholder="Cari" onChange={onSearch} /> */}
            <Table
              bordered
              stripped
              layout="auto"
              className="w-full"
              columns={tableColumns}
              dataSource={data?.result}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: data?.currentPage,
                limit: data?.size,
                total: data?.totalItems,
                previous_pages: data?.currentPage > 1,
                next_pages: !(data?.currentPage === data?.totalPages),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectPayment;
