import { BottomFormAction, Breadcrumbs } from "components";
import {
  CustomSelectForm,
  InputForm,
  SelectBankAccountForm,
  SelectCurrencyForm,
} from "components/HookForm";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useProjectAccountPost } from "hooks/projectAccount";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { useBanksQuery } from "hooks/generalSettings";

const ProjectAccountForm = () => {
  const navigate = useNavigate();

  const links = [
    {
      label: "Daftar Rekening Proyek",
      path: "/project-account",
    },
    {
      label: "Tambah Rekening",
    },
  ];

  const schema = yup.object().shape({
    account_number: yup.string().required("Nomor Rekening wajib diisi"),
    currency: yup.mixed().required("Mata Uang wajib diisi"),
    currency_type: yup.string().required("Jenis Mata Uang wajib diisi"),
    account_owner: yup.string().required("Nama Pemilik Rekening wajib diisi"),
    bank: yup.mixed().required("Bank wajib diisi"),
    branch: yup.string().required("Cabang wajib diisi"),
    note: yup.string().required("Keterangan wajib diisi"),
    type_deposito: yup.mixed().required("Kategori Deposito wajib diisi")
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      account_number: "",
      currency: null,
      currency_type: "",
      account_owner: "",
      bank: null,
      branch: "",
      note: "",
    },
  });

  const { data: banks } = useBanksQuery(["banks"]);

  const post = useProjectAccountPost();

  const onCancel = () => {
    navigate(-1);
  };
  const onSubmit = (payload) => {
    let params = {
      ...payload,
      bank_id: payload?.bank?.value,
      bank_name: payload?.bank?.label,
      currency_id: payload?.currency?.value,
      currency_name: payload?.currency?.label,
      kurs: payload?.currency?.kurs,
      currency_type: payload?.currency_type === "Local Currency" ? 1 : 2,
      type_deposito: payload?.type_deposito?.value,
    };
    delete params.bank;
    delete params.currency;
    post.mutate(params, {
      onSuccess: (data) => {
        enqueueSnackbar({
          message: data?.message ?? "Success",
          variant: "success",
        });
        navigate(-1);
      },
      onError: (error) => {
        enqueueSnackbar({
          message: error?.message ?? "Error",
          variant: "error",
        });
      },
    });
  };

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
          <div className="card-body p-4 space-y-4">
            <div className="text-lg font-semibold mb-3">
              {"Tambah Rekening"}
            </div>
            <div className="grid grid-cols-3 gap-4">
              <InputForm
                name={`account_number`}
                className={`py-2 px-4 border w-full rounded-md`}
                label={"Nomor Rekening"}
                required={true}
              />
              <SelectCurrencyForm
                name="currency"
                label="Mata Uang"
                placeholder="Pilih mata uang"
                isClearable
                required={true}
                onSelect={(v) =>
                  methods.resetField("currency_type", {
                    defaultValue:
                      v.label === "IDR" ? "Local Currency" : "Valuta Asing",
                  })
                }
              />
              <InputForm
                name={`currency_type`}
                className={`py-2 px-4 border w-full rounded-md`}
                label={"Jenis Mata Uang"}
                disabled={true}
                required={true}
              />
              <div className="col-span-3">
                <InputForm
                  name={`account_owner`}
                  className={`py-2 px-4 border w-full rounded-md`}
                  label={"Nama Pemilik Rekening"}
                  required={true}
                />
              </div>
              <div className="col-span-3 grid grid-cols-3 gap-4">
                {/* <SelectBankAccountForm
                  name="bank"
                  label="Bank"
                  placeholder="Pilih bank"
                  isClearable
                  required={true}
                /> */}
                <CustomSelectForm
                  name="bank"
                  options={banks?.data?.data?.map((bank) => ({
                    label: bank?.name,
                    value: bank?.id,
                  }))}
                  label="Bank"
                  placeholder="Pilih Bank"
                />
                <InputForm
                  name={`branch`}
                  className={`py-2 px-4 border w-full rounded-md`}
                  label={"Cabang"}
                  required={true}
                />
                <CustomSelectForm
                  name="type_deposito"
                  options={
                    [
                      {
                        label: 'Deposito',
                        value: 1
                      },
                      {
                        label: 'Bukan Deposito',
                        value: 2
                      }
                    ]
                  }
                  label="kategori deposito"
                  placeholder="Pilih kategori deposito"
                />
              </div>
              <div className="col-span-3">
                <InputForm
                  name={`note`}
                  type="textarea"
                  className={`py-2 px-4 border w-full rounded-md`}
                  label={"Keterangan"}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
        <BottomFormAction
          onCancel={onCancel}
          onSubmit={methods.handleSubmit(onSubmit)}
        />
      </FormProvider>
    </>
  );
};

export default ProjectAccountForm;
