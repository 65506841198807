import classNames from "classnames";
import { forwardRef } from "react";
import Datepicker from "react-datepicker";
import { Controller, useFormContext } from "react-hook-form";
import { AiOutlineCalendar } from "react-icons/ai";

import { isArray } from "lodash";
import "react-datepicker/dist/react-datepicker.css";

const CustomInput = forwardRef((props, ref) => {
  const { value, onClick, onClear, isError, placeholderText, disabled } = props;
  const defaultClassName =
    "flex flex-1 items-center !px-4 !py-2 border-[1px] border-gray-200 rounded-md bg-white w-full text-sm text-gray-400";
  return (
    <div
      className={classNames(...defaultClassName.split(" "), {
        [`!border-error-500`]: isError,
        ["!bg-[#EFEFEF]"]: disabled,
      })}
    >
      <input
        value={value}
        ref={ref}
        disabled={disabled}
        className="!p-0 w-full outline-none border-white text-[#1f2938]"
        readOnly
        onClick={onClick}
        placeholder={placeholderText}
      />
      <AiOutlineCalendar
        className="text-gray-400"
        size={20}
        cursor="pointer"
        onClick={onClick}
      />
      {/* {value ? (
        <IoMdCloseCircle
          className="text-gray-400"
          size={20}
          cursor="pointer"
          onClick={onClear}
        />
      ) : (
      )} */}
    </div>
  );
});

const CustomDatepickerForm = ({
  name,
  placeholder,
  showIcon = true,
  disabled,
  onClear,
  label,
  required,
  onChangeDate,
  portalId,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <div className="w-full space-y-2">
          <label className="flex gap-1 font-semibold text-[14px]">
            <span className={`label-text`}>{label}</span>
            {required && <span className="text-[#F04438]">*</span>}
          </label>
          <Datepicker
            portalId={portalId}
            disabledKeyboardNavigation
            placeholderText={placeholder}
            value={value}
            selected={isArray(value) ? "" : value}
            startDate={isArray(value) ? value?.[0] : value}
            endDate={isArray(value) ? value?.[1] : value}
            onChange={(val) => {
              onChange(val)
              onChangeDate && onChangeDate?.(val)
            }}
            wrapperClassName="w-full"
            className="text-[#1f2938]"
            dateFormat="yyyy-MM-dd"
            disabled={disabled}
            customInput={
              <CustomInput
                value={isArray(value) ? `${value?.[0]} - ${value?.[1]}` : value}
                onClear={onClear}
                isError={invalid}
                disabled={disabled}
                placeholderText={placeholder}
              />
            }
            {...props}
          />
          {invalid && (
            <div className="text-xs text-error-600 mt-2">{error?.message}</div>
          )}
        </div>
      )}
    />
  );
};

export default CustomDatepickerForm;
