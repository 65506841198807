import React, { useCallback, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { BiPencil, BiTrash } from "react-icons/bi";
import { FiCheckCircle, FiPlus } from "react-icons/fi";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { debounce } from "lodash";

import {
  useDeleteTrxJournal,
  usePostTrxJournal,
  usePutTrxJournal,
  useTrxJournal,
} from "hooks/transactionJournalMapping";

import {
  ConfirmationModal,
  FormModal,
  InformationModal,
} from "components/Modal";
import { Breadcrumbs, Button, SearchInput, Table, TitleText } from "components";
import Form from "./form";
import { enqueueSnackbar } from "notistack";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useLocation, useOutletContext } from "react-router";
import { BsEye } from "react-icons/bs";

const DEFAULT_VALUE_FORM = {
  transaction_type_name: "",
  debits_account_name: null,
  credits_account_name: null,
  transactionjournals_id: null,
};

const TransactionJournalMapping = () => {
  const updateTrxJournal = usePutTrxJournal();
  const createTrxJournal = usePostTrxJournal();
  const deleteTrxJournal = useDeleteTrxJournal();

  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [openPopupSuccessDelete, setOpenPopupSuccessDelete] = useState(false);
  const [openPopupForm, setOpenPopupForm] = useState(false);

  const [selectedData, setSelectedData] = useState(null);

  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights?.[pathname];

  const links = [
    {
      label: "Mapping Jurnal Transaksi",
    },
  ];

  const {
    data: list,
    isLoading,
    refetch,
  } = useTrxJournal([
    "tjm",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
    },
  ]);

  const schema = yup.object().shape({
    // is_parent: yup.boolean().required("Posisi akun baru wajib diisi"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUE_FORM,
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const onTriggerAdd = () => {
    methods.reset(DEFAULT_VALUE_FORM);
    setOpenPopupForm(true);
  };

  const onTriggerEdit = (data) => {
    methods.reset({
      transaction_type_name: data?.transaction_type_name,
      debits_account_name: {
        value: data?.debits_account_id,
        label: data?.debits_account_name,
        coa_code: data?.debits_account_code,
      },
      credits_account_name: {
        value: data?.credits_account_id,
        label: data?.credits_account_name,
        coa_code: data?.credits_account_code,
      },
      transactionjournals_id: data?.transaction_journal_id,
    });
    setOpenPopupForm(true);
  };

  const onTriggerDelete = (data) => {
    setSelectedData(data);
    setOpenPopupDelete(true);
  };

  const onHandleDelete = async () => {
    try {
      const res = await deleteTrxJournal.mutateAsync({
        id: selectedData?.transaction_journal_id,
      });
      setSelectedData(null);
      setOpenPopupDelete(false);
      setOpenPopupSuccessDelete(true);
      refetch();
    } catch (error) {
      enqueueSnackbar({
        message: error?.message ?? "Failed to delete data",
        variant: "error",
      });
    }
  };

  const onSubmit = (payload) => {
    if (payload) {
      if (payload?.transactionjournals_id) {
        const params = {
          id: payload?.transactionjournals_id,
          payload: {
            transaction_type_name: payload?.transaction_type_name,
            debits_account_id: payload?.debits_account_name?.value,
            debits_account_name: payload?.debits_account_name?.full_code,
            debits_account_code: payload?.debits_account_name?.full_code,
            credits_account_id: payload?.credits_account_name?.value,
            credits_account_name: payload?.credits_account_name?.full_code,
            credits_account_code: payload?.credits_account_name?.full_code,
          },
        };
        updateTrxJournal.mutate(params, {
          onSuccess: (data, variables) => {
            methods.reset(DEFAULT_VALUE_FORM);
            setOpenPopupForm(false);
            enqueueSnackbar({
              message: data?.message ?? "Success",
              variant: "success",
            });
            setTimeout(() => {
              refetch();
            }, 100);
          },
        });
      } else {
        createTrxJournal.mutate(
          {
            transaction_type_name: payload?.transaction_type_name,
            debits_account_name: payload?.debits_account_name?.full_code,
            credits_account_name: payload?.credits_account_name?.full_code,
          },
          {
            onSuccess: (data, variables) => {
              methods.reset(DEFAULT_VALUE_FORM);
              setOpenPopupForm(false);
              enqueueSnackbar({
                message: data?.message ?? "Success",
                variant: "success",
              });
              setTimeout(() => {
                refetch();
              }, 100);
            },
          }
        );
      }
    }
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(list?.currentPage) * parseInt(list?.size) -
          parseInt(list?.size) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "transaction_type_name",
      title: "Jenis Transaksi",
      dataIndex: "transaction_type_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "debits_account_name",
      title: "Akun Debet",
      dataIndex: "debits_account_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "credits_account_name",
      title: "Akun Kredit",
      dataIndex: "credits_account_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          {roleAccess?.canUpdate && (
            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onTriggerEdit(data)}
            />
          )}
          {roleAccess?.canDelete && (
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onTriggerDelete(data)}
            />
          )}
        </div>
      ),
    },
  ];

  const renderAllModal = () => (
    <>
      <ConfirmationModal
        open={openPopupDelete}
        onClose={() => setOpenPopupDelete(false)}
        onSubmit={onHandleDelete}
        text={"Apakah Anda yakin akan menghapus data ini?"}
      />
      <InformationModal open={openPopupSuccessDelete} hideHeader>
        <div className="flex items-center justify-center flex-col space-y-4 py-4">
          <IoIosCheckmarkCircleOutline className="text-[100px]" />
          <div className="text-center space-y-2 pb-4">
            <div className="font-bold text-2xl">Data berhasil Dihapus</div>
          </div>
          <Button
            onClick={() => setOpenPopupSuccessDelete(false)}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1 w-56"
          >
            Oke
          </Button>
        </div>
      </InformationModal>
      <FormModal
        title={
          methods.watch("transactionjournals_id")
            ? "Edit Mapping Jurnal Transaksi"
            : "Tambah Mapping Jurnal Transaksi"
        }
        open={openPopupForm}
        onClose={() => setOpenPopupForm(false)}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <FormProvider {...methods}>
          <Form />
        </FormProvider>
      </FormModal>
    </>
  );

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">
          {"Daftar Mapping Jurnal Transaksi"}
        </TitleText>
        <div className="flex space-x-4 justify-end flex-1">
          {roleAccess?.canCreate && (
            <Button
              startIcon={<FiPlus />}
              onClick={onTriggerAdd}
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            >
              Tambah
            </Button>
          )}
        </div>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <div className="space-x-5 flex">
              <SearchInput placeholder="Cari" onChange={onSearch} />
            </div>
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={list?.result}
              isLoading={isLoading}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: list?.currentPage,
                limit: list?.size,
                total: list?.totalItems,
                previous_pages: list?.currentPage > 1,
                next_pages: !(list?.currentPage === list?.totalPages),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionJournalMapping;
