import config from 'app/config';
import axiosInstance from 'app/interceptors';

export const getBudgetRevisionList = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/budget-revision`, { params, signal });
  return data;
}

export const getBudgetRevisionDetail = async (id) => {
  // const { data } = await axiosInstance.get(`${config.BASE_URL}/budget-revision/${id}`);
  const data = new Promise((resolve) => {
    resolve({
      result: [{
        code: "code",
        full_code: "full_code",
        coa_name: "coa_name",
        position: "position",
        level: "level",
      }]
    })
  })
  return data;
}

export const createBudgetRevisionData = async (params) => {
  const { data } = await axiosInstance.post(`${config.BASE_URL}/budget-revision`, params);
  return data;
}

export const updateBudgetRevisionData = async (params) => {
  const { data } = await axiosInstance.put(`${config.BASE_URL}/budget-revision/${params?.id}`, params?.payload);
  return data;
}

export const deleteBudgetRevisionData = async (params) => {
  const { data } = await axiosInstance.delete(`${config.BASE_URL}/budget-revision/${params?.id}`);
  return data;
}

export const downloadTemplate = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/budget-revision/template`, { params, signal });
  return data;
}

export const importBudgetRevisionData = async (payload) => {
  const { data } = await axiosInstance.post(`${config.BASE_URL}/budget-revision/import`, payload, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return data;
}