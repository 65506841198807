import { FiPlus } from "react-icons/fi";
import { useNavigate, useParams } from "react-router";

import { yupResolver } from "@hookform/resolvers/yup";
import { BottomFormAction, Breadcrumbs, Button } from "components";
import {
  CustomDatepickerForm,
  CustomSelectForm,
  InputForm,
  RadioGroupForm,
  SelectAccountForm,
} from "components/HookForm";
import FilePickerForm from "components/HookForm/FilePickerForm";
import {
  useActivityDetailQuery,
  useActivityTypes,
  useCreateDownPayment,
  useUpdateDownPayment,
} from "hooks/downPayment";
import {
  useCities,
  useCountries,
  useEmployees,
  useEmployeesNonProject,
  useUploadFile,
} from "hooks/externalService";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import * as yup from "yup";
import { getFileFromUrl, getFiscalYear } from "./util";

const destinationOptions = [
  { value: "domestic", label: "Dalam Negeri" },
  { value: "international", label: "Luar Negeri" },
];

const genderOptions = [
  { value: "L", label: "Laki-laki" },
  { value: "P", label: "Perempuan" },
];

const DownPaymentActivityForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const createDownPayment = useCreateDownPayment();
  const updateDownPayment = useUpdateDownPayment();
  const { data: countries } = useCountries();
  const { data: cities } = useCities();
  const { data: activityTypes } = useActivityTypes();
  const { data: employees } = useEmployeesNonProject();

  const uploadFile = useUploadFile();
  const isEditing = !!params?.id;

  const { data: detail } = useActivityDetailQuery(["dp-detail", params?.id], {
    enabled: isEditing,
  });

  const links = [
    {
      label: "Uang Muka",
      path: "/down-payment-activity",
    },
    {
      label: "Daftar UM Kegiatan",
      path: "/down-payment-activity",
    },
    {
      label: "Tambah Kegiatan",
    },
  ];

  const schema = yup.object().shape({
    activity_name: yup
      .string()
      .typeError("Wajib diisi")
      .required("Wajib diisi")
      .min(3, "Minimal 3 karakter"),
    activity_types: yup
      .array()
      .typeError("Wajib diisi")
      .of(
        yup.object({
          label: yup.string(),
          value: yup.string(),
        })
      ),
    additional_documents: yup
      .array()
      .typeError("Wajib diisi")
      .min(1, "Wajib diisi"),
    assignment_document: yup
      .array()
      .typeError("Wajib diisi")
      .min(1, "Wajib diisi"),
    start_date: yup.date().typeError("Wajib diisi").required(),
    end_date: yup
      .date()
      .typeError("Wajib diisi")
      .required()
      .min(
        yup.ref("start_date"),
        "Tanggal selesai tidak boleh sebelum tanggal mulai"
      ),
    component_code: isEditing
      ? yup.mixed().nullable()
      : yup
          .object({
            label: yup.string().required("Wajib diisi"),
            value: yup.string().required("Wajib diisi"),
          })
          .typeError("Wajib diisi"),
    fiscal_year: yup.object().typeError("Wajib diisi"),
    assignment_letter_number: yup.string().required("Wajib diisi"),
    assignment_letter_date: yup.date().typeError("Wajib diisi").required(),
  });

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      staffs: [
        {
          name: "",
          gender: "",
          position: "",
          division: "",
        },
      ],
    },
  });

  const setDefaultValues = useCallback(async () => {
    const assignmentDocumentFile = await getFileFromUrl(
      detail?.assignment_document?.url,
      detail?.assignment_document?.file_name
    );
    const additionalDocumentFile = await getFileFromUrl(
      detail?.additional_documents?.[0]?.url,
      detail?.additional_documents?.[0]?.file_name
    );
    methods.reset({
      fiscal_year: {
        label: detail?.fiscal_year,
        value: detail?.fiscal_year,
      },
      activity_types: detail?.activity_types?.map((type) => ({
        label: type?.name,
        value: type?.id,
      })),
      activity_name: detail?.activity_name ?? "",
      destination_type: detail?.destination_type ?? "",
      start_date: moment(detail?.start_date).toDate(),
      end_date: moment(detail?.end_date).toDate(),
      country: {
        label: detail?.country ?? "Indonesia",
        value: detail?.country ?? "Indonesia",
      },
      city: detail?.city
        ? {
            label: detail?.city,
            value: detail?.city,
          }
        : "",
      destination: {
        label: detail?.destination,
        value: detail?.destination,
      },
      component_code: {
        label: detail?.component?.name,
        value: detail?.component?.code,
      },
      pum: {
        label: `${detail?.pum?.nip} - ${detail?.pum?.name}`,
        value: {
          user_id: detail?.pum?.user_id,
          nip: detail?.pum?.nip,
          name: detail?.pum?.name,
        },
      },
      assignment_letter_number: detail?.assignment_letter_number,
      assignment_letter_date: moment(detail?.assignment_letter_date).toDate(),
      staffs: detail?.staffs?.map((staff) => ({
        user_id: {
          label: `${staff?.nip} - ${staff?.name}`,
          value: staff?.user_id,
        },
        name: staff?.name,
        nip: staff?.nip,
        gender: staff?.gender,
        position: staff?.position,
        division: staff?.division,
      })),
      additional_documents: [additionalDocumentFile],
      assignment_document: [assignmentDocumentFile],
    });
  }, [detail]);

  useEffect(() => {
    if (detail) {
      setDefaultValues();
    }
  }, [detail]);

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: "staffs",
  });

  const onCancel = () => {
    navigate(-1);
  };

  const onSubmit = async (payload) => {
    try {
      const formDataAssignment = new FormData();
      const formDataAdditional = new FormData();
      formDataAssignment.append("file", payload?.assignment_document[0]);
      formDataAdditional.append("file", payload?.additional_documents[0]);

      const uploadAssignmentFile = uploadFile.mutateAsync(formDataAssignment);
      const uploadAdditionalFile = uploadFile.mutateAsync(formDataAdditional);

      const uploadData = await Promise.all([
        uploadAssignmentFile,
        uploadAdditionalFile,
      ]);

      const params = {
        ...payload,
        activity_types: payload?.activity_types?.map((value) => value?.value),
        assignment_document: {
          file_id: uploadData?.[0]?.data?.id,
          file_name: uploadData?.[0]?.data?.name,
          file_size: uploadData?.[0]?.data?.size,
          mime_type: uploadData?.[0]?.data?.mimeType,
          path: uploadData?.[0]?.data?.key,
        },
        additional_documents: [
          {
            file_id: uploadData?.[1]?.data?.id,
            file_name: uploadData?.[1]?.data?.name,
            file_size: uploadData?.[1]?.data?.size,
            mime_type: uploadData?.[1]?.data?.mimeType,
            path: uploadData?.[1]?.data?.key,
          },
        ],
        staffs: payload?.staffs?.map((staff) => ({
          ...staff,
          user_id: staff?.user_id?.value,
        })),
        start_date: moment(payload.start_date).format("YYYY-MM-DD"),
        end_date: moment(payload.end_date).format("YYYY-MM-DD"),
        assignment_letter_date: moment(payload?.assignment_letter_date).format(
          "YYYY-MM-DD"
        ),
        fiscal_year: payload.fiscal_year?.value,
        country:
          payload?.destination_type === "domestic"
            ? "Indonesia"
            : payload?.country?.value,
        city: payload?.city?.value ?? "",
        destination: payload?.destination?.value ?? "",
        pum: payload?.pum?.value,
        component_code: payload?.component_code?.value,
      };

      if (isEditing) {
        updateDownPayment.mutate(
          { id: detail?.id, payload: params },
          {
            onSuccess: (data) => {
              enqueueSnackbar({
                message: data?.message ?? "Success",
                variant: "success",
              });
              navigate(-1);
            },
            onError: (error) => {
              enqueueSnackbar({
                message: error?.message ?? "Error",
                variant: "error",
              });
            },
          }
        );
      } else {
        createDownPayment.mutate(params, {
          onSuccess: (data) => {
            enqueueSnackbar({
              message: data?.message ?? "Success",
              variant: "success",
            });
            navigate(-1);
          },
          onError: (error) => {
            enqueueSnackbar({
              message: error?.message ?? "Error",
              variant: "error",
            });
          },
        });
      }
    } catch (error) {
      enqueueSnackbar({
        message: "Something went wrong",
        variant: "error",
      });
    }
  };

  const destinationType = methods.watch("destination_type");

  useEffect(() => {
    methods.resetField("country");
    methods.resetField("city");
    methods.resetField("destination");
  }, [destinationType, methods]);

  const handleStaffSelect = (staff, index) => {
    const staffData = employees.find((employee) => employee.id === staff.value);
    methods.setValue(`staffs.${index}.name`, staffData.name);
    methods.setValue(`staffs.${index}.nip`, staffData.nip);
    methods.setValue(`staffs.${index}.gender`, "P");
    methods.setValue(`staffs.${index}.position`, staffData.jabatan);
    methods.setValue(`staffs.${index}.division`, staffData.divisi);
  };

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="space-y-4">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="space-y-3">
                <div className="text-lg font-semibold">Informasi Kegiatan</div>
                <div className="flex flex-row gap-4 items-center">
                  <CustomSelectForm
                    name="fiscal_year"
                    options={getFiscalYear()}
                    label="Tahun Anggaran"
                    placeholder="Pilih tahun"
                    required
                  />
                  <CustomSelectForm
                    name="activity_types"
                    options={activityTypes?.map((activity) => ({
                      label: activity?.name,
                      value: activity?.id,
                    }))}
                    label="Jenis Kegiatan"
                    isMulti
                    placeholder="Pilih jenis kegiatan"
                    required
                  />
                </div>
                <InputForm
                  name="activity_name"
                  label="Nama Kegiatan"
                  className="w-full"
                  placeholder="Masukkan nama kegiatan"
                  required
                />
                <RadioGroupForm
                  label="Destinasi"
                  controllerName="destination_type"
                  values={destinationOptions}
                />
                {destinationType && (
                  <>
                    {destinationType === "domestic" ? (
                      <div className="flex flex-row gap-4 items-center">
                        <CustomSelectForm
                          name="city"
                          options={cities?.map((value) => ({
                            label: value?.namaKabupatenKota,
                            value: value?.namaKabupatenKota,
                          }))}
                          label="Kota Asal/Keberangkatan"
                          placeholder="Pilih kota"
                          required
                        />
                        <CustomSelectForm
                          name="destination"
                          options={cities?.map((value) => ({
                            label: value?.namaKabupatenKota,
                            value: value?.namaKabupatenKota,
                          }))}
                          label="Kota Tujuan Kegiatan"
                          placeholder="Pilih kota"
                          required
                        />
                      </div>
                    ) : (
                      <div className="flex flex-row gap-4 items-center">
                        <CustomSelectForm
                          name="country"
                          options={countries?.map((country) => ({
                            label: country?.namaNegara,
                            value: country?.namaNegara,
                          }))}
                          label="Negara Asal/Keberangkatan"
                          placeholder="Pilih negara"
                          required
                        />
                        <CustomSelectForm
                          name="destination"
                          options={countries?.map((country) => ({
                            label: country?.namaNegara,
                            value: country?.namaNegara,
                          }))}
                          label="Negara Tujuan Kegiatan"
                          placeholder="Pilih negara"
                          required
                        />
                      </div>
                    )}
                  </>
                )}
                <div className="flex flex-row gap-4 items-center"></div>
                <div className="flex flex-row gap-4 items-center">
                  <CustomDatepickerForm
                    name="start_date"
                    label="Tanggal Mulai Kegiatan"
                    placeholder={"dd/mm/yyyy"}
                    required
                  />
                  <CustomDatepickerForm
                    name="end_date"
                    label="Tanggal Selesai Kegiatan"
                    placeholder={"dd/mm/yyyy"}
                    required
                  />
                </div>
                <CustomSelectForm
                  name="pum"
                  options={employees?.map((value) => ({
                    label: `${value?.nip ?? ""} - ${value?.name ?? ""}`,
                    value: {
                      user_id: value?.id,
                      nip: value?.nip,
                      name: value?.name,
                    },
                  }))}
                  label="PUM"
                  placeholder="Pilih PUM"
                  required
                />
              </div>
            </div>
          </div>

          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="space-y-3">
                <div className="text-lg font-semibold">Akun Kegiatan</div>
                <SelectAccountForm
                  name="component_code"
                  label="Akun Belanja"
                  placeholder="Pilih Akun"
                  level="8"
                />
              </div>
            </div>
          </div>

          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="space-y-3">
                <div className="text-lg font-semibold">Surat Tugas</div>
                <div className="flex flex-row gap-4 items-center">
                  <InputForm
                    name="assignment_letter_number"
                    label="No Surat Tugas"
                    className="w-full"
                    placeholder="Masukkan nomor surat tugas"
                    required
                  />
                  <CustomDatepickerForm
                    name="assignment_letter_date"
                    label="Tanggal Surat Tugas"
                    placeholder={"dd/mm/yyyy"}
                    required
                  />
                </div>
                <FilePickerForm
                  name="assignment_document"
                  label="Dokumen Pendukung"
                  required
                />
              </div>
              <div className="border-t-[1px] border-gray-200 py-4">
                <div className="space-y-4">
                  <span className="text-lg text-primary-600 font-semibold ">
                    Daftar Pegawai yang Dimasukkan ke dalam Surat Tugas
                  </span>
                  {fields?.map((data, index) => {
                    return (
                      <div className="flex flex-row items-end" key={data.id}>
                        <div className="flex-1 space-y-3">
                          <div className="flex flex-row gap-4 items-center flex-1">
                            <CustomSelectForm
                              name={`staffs.${index}.user_id`}
                              options={employees?.map((value) => ({
                                label: `${value?.nip ?? ""} - ${
                                  value?.name ?? ""
                                }`,
                                value: value?.id,
                              }))}
                              label="Pegawai"
                              placeholder="Pilih pegawai"
                              onSelect={(val) => {
                                handleStaffSelect(val, index);
                              }}
                            />
                          </div>
                          <div className="flex flex-row gap-4 items-center pb-4">
                            <RadioGroupForm
                              label="Jenis Kelamin"
                              controllerName={`staffs.${index}.gender`}
                              values={genderOptions}
                              disabled
                            />
                            <InputForm
                              name={`staffs.${index}.position`}
                              label="Jabatan"
                              className="w-full"
                              disabled
                            />
                            <InputForm
                              name={`staffs.${index}.division`}
                              label="Divisi"
                              className="w-full"
                              disabled
                            />
                          </div>
                        </div>
                        <Button
                          startIcon={<BiTrash size={18} />}
                          className="bg-error-500 border-error-500 px-2 self-end mx-4 h-10 mb-4"
                          size="sm"
                          onClick={() => remove(index)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-row justify-end">
                <Button
                  startIcon={<FiPlus />}
                  className=" bg-white border-[1px] border-gray-200 rounded-md text-black hover:text-white"
                  onClick={() => {
                    append({
                      name: "",
                      gender: "",
                      position: "",
                      division: "",
                    });
                  }}
                >
                  Tambah Pegawai
                </Button>
              </div>
            </div>
          </div>
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-4 space-y-4">
              <div className="space-y-3">
                <div className="text-lg font-semibold">Dokumen Pendukung</div>

                <FilePickerForm
                  name="additional_documents"
                  label="Dokumen Pendukung"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="h-24" />
        <BottomFormAction
          onCancel={onCancel}
          onSubmit={methods.handleSubmit(onSubmit)}
        />
      </FormProvider>
    </>
  );
};

export default DownPaymentActivityForm;
