import { Table } from "components";
import moment from "moment";
import React from "react";
import { HiOutlineEye } from "react-icons/hi";

const RevisionHistory = ({ onDetail }) => {
  const resource = [];

  const tableColumns = [
    {
      id: "assignment_date",
      title: "Tanggal Pengajuan",
      dataIndex: "assignment_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-normal">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "approval_date",
      title: "Tanggal Persetujuan",
      dataIndex: "approval_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-nowrap">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "version_before_revision",
      title: "Versi sebelum Revisi",
      dataIndex: "version_before_revision",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis whitespace-nowrap">
          {value}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => onDetail(data)}
          />
        </div>
      ),
    },
  ];
  return (
    <Table
      bordered
      stripped
      layout="fixed"
      className="mb-4"
      columns={tableColumns}
      dataSource={resource}
      // isLoading={isLoading}
      // onChangePage={setNewPage}
      // onChangeRowsPerPage={setNewLimit}
      // pagination={list?.pagination}
    />
  );
};
export default RevisionHistory;
