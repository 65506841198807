import {
  BottomFormAction,
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
} from "components";
import CollapsingCard from "components/Card/CollapsingCard";
import {
  CustomDatepickerForm,
  CustomSelectForm,
  InputForm,
  NumberInputForm,
  SelectCoaByLevelForm,
} from "components/HookForm";
import FilePickerForm from "components/HookForm/FilePickerForm";
import { find, map, sum, sumBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router";
import Contractual from "./Contractual";
import ThirdParty from "./ThirdParty";
import NonContractual from "./NonContractual";
import {
  useSubmissionDetailQuery,
  useCreateContractual,
  useUpdateContractual,
  useCreateNonContractualParticipant,
  useUpdateNonContractualParticipant,
  useCreateVendor,
  useUpdateVendor,
  useCreateNonContractualStaff,
  useUpdateNonContractualStaff,
} from "hooks/directTransaction";
import {
  getContractualPayload,
  getNonContractualParticipantPayload,
  getNonContractualStaffPayload,
  getVendorPayload,
} from "./util";
import { NumericFormat } from "react-number-format";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useUploadFile } from "hooks/externalService";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getFileFromUrl } from "pages/DownPayment/util";
import Vendor from "./Vendor";

// export const DEFAULT_VALUE_TAX = {
//   tax: null,
//   tax_value: "",
//   billing_code: "",
//   total_paid: "",
// };
// export const DEFAULT_VALUE_TAXES = {
//   tax: null,
//   billing_code: "",
//   value: "",
// };
// export const DEFAULT_VALUE_ACTIVITY_DETAIL = {
//   activity_detail: null,
//   unit_cost: "",
//   qty: "",
//   volume: "",
//   taxes: [DEFAULT_VALUE_TAXES],
// };
// export const DEFAULT_VALUE_ACCOUNTS = {
//   shipping_account: null,
//   activity_details: [DEFAULT_VALUE_ACTIVITY_DETAIL],
// };

const DirectTransaction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const uploadFile = useUploadFile();
  const isEditing = !!params?.id;

  const links = [
    {
      label: "Transaksi Langsung (LS)",
      path: "/direct-transactions",
    },
    {
      label: "Daftar Pengajuan LS",
      path: "/direct-transactions",
    },
    {
      label: "Tambah Kegiatan",
    },
  ];

  const paymentTypeOptions = useMemo(
    () => [
      { value: 1, label: "Pembayaran Kontraktual" },
      { value: 2, label: "Pembayaran Non Kontraktual" },
      { value: 3, label: "Pembayaran Vendor" },
    ],
    []
  );

  const trxTypeOptions = useMemo(
    () => [
      { value: 1, label: "Pembayaran Perjadin Pegawai" },
      { value: 2, label: "Pembayaran Undangan" },
      { value: 3, label: "Pembayaran Narasumber" },
    ],
    []
  );

  const { data: detail } = useSubmissionDetailQuery(
    ["submission-detail", { id: params?.id }],
    { enabled: isEditing }
  );

  const contractualPost = useCreateContractual();
  const contractualPut = useUpdateContractual();
  const nonContractualStaffPost = useCreateNonContractualStaff();
  const nonContractualStaffPut = useUpdateNonContractualStaff();
  const nonContractualParticipantPost = useCreateNonContractualParticipant();
  const nonContractualParticipantPut = useUpdateNonContractualParticipant();
  const vendorPost = useCreateVendor();
  const vendorPut = useUpdateVendor();

  const schema = yup.object().shape({
    supporting_document: yup
      .array()
      .typeError("Wajib diisi")
      .min(1, "Wajib diisi"),
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      description: "",
      payment_type: null,
      transaction_date: "",
      trx_type: null,
      invoice_number: "",
      component: "",
      total_trx_value: 0,
      thirdparty: {
        vendor_procurement: null,
        vendor: null,
        account_name: "",
        bank: "",
      },
      accounts: [
        {
          shipping_account: null,
          activity_detail: null,
          details: [],
          activity_details: [
            {
              activity_detail: null,
              unit_cost: "",
              qty: "",
              volume: "",
              taxes: [
                {
                  tax: null,
                  billing_code: "",
                  value: "",
                },
              ],
            },
          ],
        },
      ],
    },
  });

  const supportingFile = async () => {
    const supportingDocs = await getFileFromUrl(
      detail?.documents?.[0]?.url,
      detail?.documents?.[0]?.file_name
    );
    if (supportingDocs) {
      methods.resetField("supporting_document", {
        defaultValue: [supportingDocs],
      });
    }
  };

  useEffect(() => {
    if (detail?.result) {
      supportingFile();
      const data = detail?.result;
      if (
        data?.payment_type === "contractual" ||
        data?.payment_type === "vendor"
      ) {
        methods.reset({
          description: data?.description,
          payment_type:
            data?.payment_type === "contractual"
              ? find(paymentTypeOptions, { value: 1 })
              : find(paymentTypeOptions, { value: 3 }),
          transaction_date: moment(data?.transaction_date).toDate(),
          invoice_number: data?.invoice_number,
          component: {
            value: data?.component_code,
            label: data?.component_code,
            full_code: data?.component_code,
          },
          total_trx_value: data?.total_value,
          accounts: map(data?.accounts, (account) => ({
            shipping_account: {
              value: account?.account_code,
              label: account?.account_code,
              full_code: account?.account_code,
            },
            activity_details: map(account?.details, (detail) => ({
              activity_detail: {
                value: detail?.detail_code,
                label: detail?.detail_code,
                full_code: detail?.detail_code,
              },
              value: detail?.value, // vendor
              unit_cost: detail?.value, // contractual
              qty: detail?.qty, // contractual
              volume: detail?.volume, // contractual
              taxes: map(detail?.taxes, (tax) => ({
                tax: {
                  value: tax?.tax_id,
                  label: tax?.tax_name,
                },
                billing_code: tax?.billing_code,
                value: tax?.value,
              })),
            })),
          })),
          thirdparty: {
            vendor_procurement: {
              value: data?.vendor?.vendor_id,
              label: data?.vendor?.name,
            },
            vendor: {
              value: data?.vendor?.bank_account_number,
              label: data?.vendor?.bank_account_number,
            },
            account_name: data?.vendor?.bank_account_name,
            bank: data?.vendor?.bank_name,
          },
        });
      } else if (data?.payment_type === "non_contractual") {
        methods.reset({
          description: data?.description,
          payment_type: find(paymentTypeOptions, { value: 2 }),
          transaction_date: moment(data?.transaction_date).toDate(),
          trx_type:
            data?.transaction_type === "staff"
              ? { value: 1, label: "Pembayaran Perjadin Pegawai" }
              : data?.transaction_type === "invite"
              ? { value: 2, label: "Pembayaran Undangan" }
              : { value: 3, label: "Pembayaran Narasumber" },
          invoice_number: data?.invoice_number,
          component: {
            value: data?.component_code,
            label: data?.component_code,
            full_code: data?.component_code,
          },
          total_trx_value: data?.total_value,
          accounts: map(data?.accounts, (account) => {
            return {
              shipping_account: {
                value: account?.account_code,
                label: account?.account_code,
                full_code: account?.account_code,
              },
              activity_detail: {
                value: account?.detail_code,
                label: account?.detail_code,
                full_code: account?.detail_code,
              },
              details: map(account?.details, (detail) => ({
                detailInfo: detail?.participant,
                name: detail?.participant?.name,
                is_ministry_employee: detail?.ministry_staff,
                requirement_item: {
                  ...detail?.sbm,
                  sbm_value: detail?.sbm?.value,
                  value: detail?.sbm?.id,
                  label: detail?.sbm?.description,
                },
                qty: detail?.qty,
                value: detail?.value,
                sub_total: detail?.subtotal,
                taxes: map(detail?.taxes, (tax) => ({
                  tax: { value: tax?.tax_id, label: tax?.tax_name },
                  billing_code: tax?.billing_code,
                  value: tax?.value,
                })),
                total_taxes: detail?.taxes?.reduce(
                  (acc, o) => acc + o?.value,
                  0
                ),
                account_number: detail?.bank_account_number,
                account_name: detail?.bank_account_name,
                bank: detail?.bank_name,
                total: detail?.total_value,
              })),
            };
          }),
        });
      }
    }
    return;
  }, [detail?.result]);

  const onCancel = () => {
    navigate(-1);
  };

  const onSubmit = async (payload) => {
    const formDataSupporting = new FormData();
    formDataSupporting.append("file", payload?.supporting_document[0]);

    const uploadSupportingFile = uploadFile.mutateAsync(formDataSupporting);

    const uploadData = await Promise.all([uploadSupportingFile]);

    let bodyToSend = {};
    let action = {
      post: null,
      put: null,
    };
    payload.documents = uploadData?.[0]?.data?.id
      ? [
          {
            file_id: uploadData?.[0]?.data?.id,
            file_name: uploadData?.[0]?.data?.name,
            file_size: uploadData?.[0]?.data?.size,
            mime_type: uploadData?.[0]?.data?.mimeType,
            path: uploadData?.[0]?.data?.key,
          },
        ]
      : [];

    if (methods.watch("payment_type")?.value === 1) {
      bodyToSend = getContractualPayload(payload);
      action.post = contractualPost;
      action.put = contractualPut;
    } else if (methods.watch("payment_type")?.value === 2) {
      if (methods.watch("trx_type")?.value === 1) {
        bodyToSend = getNonContractualStaffPayload(payload);
        action.post = nonContractualStaffPost;
        action.put = nonContractualStaffPut;
      } else {
        bodyToSend = getNonContractualParticipantPayload(payload);
        action.post = nonContractualParticipantPost;
        action.put = nonContractualParticipantPut;
      }
    } else {
      bodyToSend = getVendorPayload(payload);
      action.post = vendorPost;
      action.put = vendorPut;
    }

    if (isEditing) {
      action.put.mutate(
        { id: params?.id, payload: bodyToSend },
        {
          onSuccess: (data) => {
            enqueueSnackbar({
              message: data?.message ?? "Success",
              variant: "success",
            });
            navigate(-1);
          },
          onError: (error) => {
            enqueueSnackbar({
              message: error?.message ?? "Error",
              variant: "error",
            });
          },
        }
      );
    } else {
      action.post.mutate(bodyToSend, {
        onSuccess: (data) => {
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          navigate(-1);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message ?? "Error",
            variant: "error",
          });
        },
      });
    }
  };

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="space-y-4">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
            <div className="card-body p-4 space-y-4">
              <div className="text-lg font-semibold mb-3">
                {isEditing
                  ? "Edit Kegiatan Untuk Pengajuan LS"
                  : "Tambah Kegiatan Untuk Pengajuan LS"}
              </div>
              <InputForm
                name={`description`}
                type="textarea"
                className={`py-2 px-4 border w-full rounded-md`}
                label={"Uraian Pengajuan"}
                required={true}
              />
              <CustomSelectForm
                name="payment_type"
                options={paymentTypeOptions}
                label="Jenis Pembayaran"
                placeholder="Pilih jenis pembayaran"
                onSelect={(selected) => {
                  if (selected?.value === 1 || selected?.value === 3) {
                    methods.reset({
                      ...methods.getValues(),
                      total_trx_value: 0,
                      accounts: [
                        {
                          shipping_account: null,
                          activity_details: [
                            {
                              activity_detail: null,
                              unit_cost: "",
                              qty: "",
                              volume: "",
                              taxes: [
                                {
                                  tax: null,
                                  billing_code: "",
                                  value: "",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      thirdparty: {
                        vendor_procurement: null,
                        vendor: null,
                        account_name: "",
                        bank: "",
                      },
                    });
                  } else {
                    methods.reset({
                      ...methods.getValues(),
                      total_trx_value: 0,
                      accounts: [
                        {
                          shipping_account: null,
                          activity_detail: null,
                          details: [],
                        },
                      ],
                    });
                  }
                }}
              />
              <div
                className={`grid grid-cols-1 ${
                  methods.watch("payment_type")?.value === 2
                    ? "md:grid-cols-3"
                    : "md:grid-cols-2"
                } gap-4`}
              >
                <CustomDatepickerForm
                  name="transaction_date"
                  label="Tanggal SPJ / Tagihan / Transaksi"
                  placeholder={"dd/mm/yyyy"}
                />
                {methods.watch("payment_type")?.value === 2 && (
                  <CustomSelectForm
                    name="trx_type"
                    options={trxTypeOptions}
                    label="Jenis Transaksi"
                    placeholder="Pilih jenis transaksi"
                  />
                )}
                <InputForm
                  name={`invoice_number`}
                  label={"Nomor Invoice"}
                  className={`py-2 px-4 border w-full rounded-md`}
                  placeholder="INV/--/---/---/----"
                />
              </div>
              {!location?.state?.role?.includes("pjk") && (
                <>
                  {/* <CustomSelectForm
                    name="activity"
                    options={[]}
                    label="Kegiatan"
                    placeholder="Pilih kegiatan"
                  /> */}
                  {/* <CustomSelectForm
                    name="output_detail"
                    options={[]}
                    label="Rincian Output"
                    placeholder="Pilih rincian output"
                  /> */}
                </>
              )}
              <SelectCoaByLevelForm
                name="component"
                className="w-full"
                label="Komponen"
                placeholder="Pilih komponen"
                level={8}
                isDisabled={!methods.watch("payment_type")}
                menuPortalTarget={document.body}
                onSelect={() => {
                  if (
                    methods.watch("payment_type")?.value === 1 ||
                    methods.watch("payment_type")?.value === 3
                  ) {
                    methods.reset({
                      ...methods.getValues(),
                      total_trx_value: 0,
                      accounts: [
                        {
                          shipping_account: null,
                          activity_details: [
                            {
                              activity_detail: null,
                              unit_cost: "",
                              qty: "",
                              volume: "",
                              taxes: [
                                {
                                  tax: null,
                                  billing_code: "",
                                  value: "",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      thirdparty: {
                        vendor_procurement: null,
                        vendor: null,
                        account_name: "",
                        bank: "",
                      },
                    });
                  } else {
                    methods.reset({
                      ...methods.getValues(),
                      total_trx_value: 0,
                      accounts: [
                        {
                          shipping_account: null,
                          activity_detail: null,
                          details: [],
                        },
                      ],
                    });
                  }
                }}
              />
              {/* <div className="space-y-2">
                <label className="flex gap-1 font-semibold text-[14px]">
                  <span className={`label-text`}>Total Nilai Transaksi</span>
                </label>
                <NumericFormat
                  value={sumBy(methods.watch("accounts"), (account) =>
                    sumBy(
                      account.activity_details,
                      (activity_detail) =>
                        parseFloat(activity_detail.unit_cost) *
                          (parseFloat(activity_detail.volume) +
                            parseFloat(activity_detail.qty)) -
                        parseFloat(
                          activity_detail.taxes?.reduce(
                            (acc, o) => acc + o?.value,
                            0
                          )
                        )
                    )
                  )}
                  placeholder="0"
                  className="w-full"
                  customInput={Input}
                  allowLeadingZeros
                  thousandSeparator="."
                  decimalSeparator=","
                  disabled
                />
              </div> */}
              <NumberInputForm
                disabled
                name={`total_trx_value`}
                label={"Total Nilai Transaksi"}
                className={`py-2 px-4 border w-full rounded-md`}
              />

              {methods.watch("payment_type")?.value === 1 && <Contractual />}
              {methods.watch("payment_type")?.value === 3 && <Vendor />}
            </div>
          </div>

          {(methods.watch("payment_type")?.value === 1 ||
            methods.watch("payment_type")?.value === 3) && <ThirdParty />}
          {methods.watch("payment_type")?.value === 2 &&
            methods.watch("trx_type")?.value && <NonContractual />}

          <Card>
            <CardHeader>Dokumen Bukti Pendukung</CardHeader>
            <CardBody>
              <FilePickerForm
                name="supporting_document"
                label="Dokumen Bukti Pendukung"
              />
            </CardBody>
          </Card>
          <div className="h-20" />
        </div>
        <BottomFormAction
          onCancel={onCancel}
          onSubmit={methods.handleSubmit(onSubmit)}
        />
      </FormProvider>
    </>
  );
};

export default DirectTransaction;
