export const TIPE_DEPOSITO = [
  {
    label: 'APBN',
    value: 1
  },
  {
    label: 'Project',
    value: 2
  }
]

export const findDepositoByValue = (value) => {
  const foundItem = TIPE_DEPOSITO.find(item => item.value === value);
  return foundItem ? foundItem.label : null;
};