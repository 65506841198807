import { NumberInputForm, SelectProjectAccountForm } from "components/HookForm";
import { filter } from "lodash";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const Conversion = () => {
  const { resetField, watch } = useFormContext();

  useEffect(() => {
    if (
      watch("account_number") &&
      watch("nominal_transfer") &&
      parseFloat(watch("account_number")?.balance) &&
      parseFloat(watch("nominal_transfer")) >
        parseFloat(watch("account_number")?.balance)
    ) {
      resetField("nominal_transfer", {
        defaultValue: watch("account_number")?.balance,
      });
    }
  }, [watch("nominal_transfer")]);

  return (
    <div className="grid grid-cols-3 gap-4">
      <div className="col-span-3">
        <SelectProjectAccountForm
          name="account_number"
          label="Nomor Rekening Sumber Konversi"
          placeholder="Pilih nomor rekening sumber"
          isClearable
          required={true}
          currency_name={"USD"}
          onSelect={() => {
            resetField("target_account_number", { defaultValue: null });
          }}
        />
      </div>
      <NumberInputForm
        name={`nominal_transfer`}
        className={`py-2 px-4 border w-full rounded-md`}
        label={"Nominal Pemindahan"}
        required={true}
      />
      <NumberInputForm
        name={`kurs`}
        className={`py-2 px-4 border w-full rounded-md`}
        label={"Kurs Konversi (IDR)"}
        required={true}
      />
      <NumberInputForm
        name={`convertion_result`}
        className={`py-2 px-4 border w-full rounded-md`}
        label={"Total Hasil Konversi (IDR)"}
        disabled
        required={true}
      />
      <div className="col-span-3">
        <SelectProjectAccountForm
          name="target_account_number"
          label="Nomor Rekening Tujuan Konversi"
          placeholder="Pilih nomor rekening tujuan"
          isClearable
          isDisabled={!watch("account_number")}
          required={true}
          currency_name={"IDR"}
          getOptions={(value) => {
            return filter(
              value,
              (opt) => opt.value !== watch("account_number")?.value
            );
          }}
        />
      </div>
    </div>
  );
};
export default Conversion;
